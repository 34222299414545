import React from "react"
import { graphql } from "gatsby"
import { MediaPlusMessagingProps } from "./MediaPlusMessaging"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { MessagesContainer } from "../../../../components/Messages/MessagesContainer"
import styled from "styled-components"
import device from "../../../../components/Theme/devices"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"

const ImageContainer = styled(GridCell)`
  margin-bottom: 30px;
  @media (${device.tablet}) {
    margin-bottom: 0;
  }
`

const MessagesWrapper = styled(GridCell)`
  @media (${device.desktop}) {
    margin-left: 60px;
  }
`

export const MediaPlusMessaging: React.FC<MediaPlusMessagingProps> = ({
  image,
  messaging,
  ...props
}) => (
  <GridLayout padTop padBottom {...props}>
    <ImageContainer column={"4 / 8"} tabletCol={" 2 / 5"} tabletRow={1} row={1}>
      {image && <DatoCmsAsset {...image} />}
    </ImageContainer>
    <MessagesWrapper
      column={"8 / 13"}
      tabletCol={" 5 / 8"}
      tabletRow={1}
      row={1}
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
      leftMargin={"all"}
      rightMargin={"phone-only"}
    >
      <MessagesContainer {...messaging} />
    </MessagesWrapper>
  </GridLayout>
)

export const fragment = graphql`
  fragment MediaPlusMessagingData on DatoCmsMediaPlusMessaging {
    image {
      ...DatoCmsAssetData
    }
    messaging {
      ...MessagingData
    }
    greenish
  }
`
