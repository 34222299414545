import { Input } from "./Input"
import { Label } from "./Label"
import { TextArea } from "./TextArea"
import { RadioGroup } from "./RadioGroup"
import { CheckboxGroup } from "./CheckboxGroup"
import { Select } from "./Select"

export const Forms = {
  Input,
  Label,
  TextArea,
  RadioGroup,
  CheckboxGroup,
  Select,
}
