import styled from "styled-components"
import { GridCell } from "../../../../components/GridLayout"
import { GridCellProps } from "../../../../components/GridLayout/GridLayout"

export const ImageCell = styled(GridCell).attrs({
  row: 1,
  tabletRow: 1,
  column: "3 / 13",
  tabletCol: "2 / 8",
} as GridCellProps)``

export const BubbleCell = styled(GridCell).attrs({
  row: 2,
  tabletRow: 2,
  column: "4 / 8",
  tabletCol: "3 / 7",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
} as GridCellProps)`
  margin-top: -10%;
`
