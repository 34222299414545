import React, { useMemo } from "react"
import * as S from "./styled"
import { DatoCmsHtmlText } from "../DatoCmsHtmlText"
import { DatoCmsAsset as IDatoCmsAsset } from "../DatoCmsAsset/DatoCmsAsset"
import { DatoCmsAsset } from "../DatoCmsAsset"

type GalleryItemProps = {
  image?: IDatoCmsAsset
  title: string
  subTitle: string
  description: string
  [other: string]: any
}

export const GalleryItem: React.FC<
  GalleryItemProps & { LinkComp?: any; linkProps?: any }
> = ({
  image,
  title,
  subTitle,
  description,
  LinkComp,
  linkProps,
  ...props
}) => {
  const enhancedImage = useMemo(
    () =>
      image
        ? image?.isImage
          ? image
          : {
              ...image,
              customData: {
                ...(image?.customData || {}),
                playOnHover: "true",
              },
            }
        : undefined,
    [image]
  )
  return (
    <S.Container as={LinkComp || undefined} {...linkProps} {...props}>
      {enhancedImage && <DatoCmsAsset {...enhancedImage} />}
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subTitle}</S.Subtitle>
      <DatoCmsHtmlText as={S.Content} html={description} />
    </S.Container>
  )
}
