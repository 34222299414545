import React from "react"
import { GridLayout } from "../../../../components/GridLayout"
import { GalleryItem } from "../../../../components/GalleryItem"
import { Body16, SectionTitle } from "../../../../components/Texts"
import * as S from "./styled"
import { graphql } from "gatsby"
import { GalleryItem as GalleryItemType, GalleryProps } from "./Gallery"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import {
  ConfigurationContextType,
  useConfiguration,
} from "../../../../context/configuration"
import { parseDatoCmsPageLink } from "../../../../components/DatoCmsAction"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset/DatoCmsAsset"

const getItemImage: (i: GalleryItemType) => DatoCmsAsset | undefined = i => {
  switch (i.__typename) {
    case "DatoCmsTeamMember":
      return i.photo || undefined
    default:
      return undefined
  }
}

const getItemTitle: (i: GalleryItemType) => string = i => {
  switch (i.__typename) {
    case "DatoCmsTeamMember":
      return i.name
    default:
      return ""
  }
}

const getItemSubTitle: (i: GalleryItemType) => string = i => {
  switch (i.__typename) {
    case "DatoCmsTeamMember":
      return i.role || ""
    default:
      return ""
  }
}

const getItemDescription: (i: GalleryItemType) => string = i => {
  switch (i.__typename) {
    case "DatoCmsTeamMember":
      return i.description || ""
    default:
      return ""
  }
}

const getLink = (i: GalleryItemType, config: ConfigurationContextType) => {
  switch (i.__typename) {
    case "DatoCmsTeamMember":
      return parseDatoCmsPageLink(i, config)
    default:
      return {}
  }
}

export const Gallery: React.FC<GalleryProps> = props => {
  const config = useConfiguration()
  return (
    <GridLayout {...props}>
      <S.TitleCell
        as={SectionTitle}
        centered
        column={"3 / 12"}
        tabletCol={"2 / 8"}
        tabletRow={1}
        row={1}
        leftMargin={"phone-only"}
        rightMargin={"phone-only"}
      >
        {props.title}
      </S.TitleCell>
      <S.DescriptionCell
        column={"4 / 12"}
        tabletCol={"3 / 7"}
        tabletRow={2}
        row={2}
        rightMargin={"all"}
        leftMargin={"all"}
        as={DatoCmsHtmlText}
        forwardedAs={Body16}
        centered
        html={props.description || ""}
      />
      <S.Container>
        {props.items.map(item => (
          <GalleryItem
            key={item.id}
            image={getItemImage(item)}
            title={getItemTitle(item)}
            subTitle={getItemSubTitle(item)}
            description={getItemDescription(item)}
            {...getLink(item, config)}
          />
        ))}
      </S.Container>
    </GridLayout>
  )
}

export const fragment = graphql`
  fragment GalleryData on DatoCmsGallery {
    id
    __typename
    title
    description
    items {
      __typename
      ... on DatoCmsTeamMember {
        ...TeamMember_WithPhoto
      }
    }
  }
`
