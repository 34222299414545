import React from "react"
import { PillSetProps } from "./PillSet"
import { graphql } from "gatsby"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import * as S from "./styled"
import { ArrowedLink } from "../../../../components/Link"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"

export const PillSet: React.FC<PillSetProps> = props => (
  <GridLayout {...props}>
    <GridCell
      row={"1"}
      tabletRow={1}
      column={"3 / 8"}
      tabletCol="2 / 8"
      rightMargin={"phone-only"}
      leftMargin={"phone-only"}
    >
      <S.Title topLevel>{props.title}</S.Title>
    </GridCell>
    <S.PillContainer>
      {props.pills.map(pill => (
        <DatoCmsAction {...pill} />
      ))}
    </S.PillContainer>
    <S.ActionContainer>
      {props.action && (
        <DatoCmsAction
          {...props.action}
          content={<ArrowedLink as="span" accent label={props.action.label} />}
        />
      )}
    </S.ActionContainer>
  </GridLayout>
)

export const fragment = graphql`
  fragment PillSetData on DatoCmsPillSet {
    __typename
    id
    title
    pills {
      ...ActionData
    }
    action {
      ...ActionData
    }
    greenish
  }
`
