import React from "react"
import { graphql } from "gatsby"
import { ImageMessages } from "./ImageMessages"
import { MessagingWithDescription } from "./MessagingWithDescription"
import { DescriptionPlusMessagingProps } from "./descriptionPlusMessaging"

export const DescriptionPlusMessaging: React.FC<DescriptionPlusMessagingProps> = props =>
  props.alternativeLayout ? (
    <ImageMessages {...props} />
  ) : (
    <MessagingWithDescription {...props} />
  )

export const Fragment = graphql`
  fragment DescriptonAndMessagingData on DatoCmsDescriptionPlusMessaging {
    __typename
    id
    description
    text
    alternativeLayout
    messaging {
      ...MessagingData
    }
    action {
      ...ActionData
    }
    greenish
  }

  fragment MessagingData on DatoCmsConversation {
    __typename
    conversationType
    conversationName {
      name
      image {
        fluid(maxWidth: 100, imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        video {
          thumbnailUrl
        }
      }
    }
    messages {
      atLeftSide
      author
      hour
      message
      image {
        ...DatoCmsAssetData
      }
      pageLink {
        ...PageLink
      }
    }
  }
`
