import styled from "styled-components"
import { GridCell } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"
import { Small } from "../../../../components/Texts"

export const TitleCell = styled(GridCell)`
  text-align: left;
  @media (${device.tablet}) {
    text-align: center;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
`

export const FaceCell = styled(GridCell).attrs(props => ({
  row: "2",
  //@ts-ignore
  tabletRow: 2 + props.index,
  tabletCol: "3 / 7",
  rightMargin: "mobile",
  leftMargin: "mobile",
}))<{ index: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-left: 2.5em;
  }
  color: ${props => props.theme.textColorLighter};
  text-align: center;

  margin-top: 0;
  @media (${device.phone}) {
    && + && {
      margin-top: 25px;
    }
  }
  @media (${device.tablet}) {
    margin-top: 0;
    margin-bottom: 20px;
  }

  ${Small} {
    padding: 0 50px;
  }
`

export const FaceContainer = styled.div`
  height: 250px;
  width: 250px;
  box-sizing: border-box;

  svg {
    max-height: 100%;
  }
`

export const ActionContainer = styled(GridCell).attrs({
  row: "3",
  tabletRow: 6,
  column: "5 / 11",
  tabletCol: "2 / 8",
  leftMargin: "all",
  rightMargin: "all",
})`
  text-align: center;
  margin-top: 53px;
  @media (${device.tablet}) {
    margin-top: 32px;
  }
  @media (${device.desktop}) {
    margin-top: 13px;
  }
`
