import { useSpring } from "react-spring"
import { useInView } from "react-intersection-observer"
import { useState } from "react"
import { useAfterLoading } from "../context/loading"
import { useTransitionStatus } from "./usePageTransitionStatus"

export const useImageSlideInSpring = ({
  pause,
  onRest,
}: {
  pause?: boolean
  onRest?: () => void
} = {}) => {
  const loaded = useAfterLoading()
  const transitionEnded = useTransitionStatus()

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 })
  const [rested, setRested] = useState<boolean>(false)
  const [spring] = useSpring(
    {
      from: {
        transform: "translateY(100%)",
      },
      to: {
        transform: "translateY(0%)",
      },
      pause: pause || !inView || !loaded || !transitionEnded,
      onRest: () => {
        setRested(true)
        onRest && onRest()
      },
    },
    [pause, inView, loaded, transitionEnded]
  )

  return { spring, ref, rested }
}
