import styled from "styled-components"
import { GridCell, GridCellProps } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"

export const ImagCell = styled(GridCell).attrs(
  (props: { overlay: boolean }) =>
    ({
      row: 1,
      tabletRow: 1,
      column: props.overlay ? "6 / 12" : "9 / 12",
      tabletCol: props.overlay ? "4 / 8" : "5 / 8",
      leftMargin: props.overlay ? "all" : "phone-only",
      rightMargin: props.overlay ? "all" : "phone-only",
    } as GridCellProps)
)<{ overlay: boolean }>`
  z-index: 1;
`

export const DescriptionCell = styled(GridCell).attrs(
  (props: { overlay: boolean; centerText: boolean }) =>
    ({
      row: 1,
      tabletRow: 1,
      column: props.overlay ? "4 / 8" : "3 / 7",
      tabletCol: props.overlay ? "2 / 6" : "2 / 5",
      leftMargin: props.overlay ? "all" : "phone-only",
      rightMargin: props.overlay ? "all" : "phone-only",
    } as GridCellProps)
)<{ overlay: boolean; centerText: boolean }>`
  text-align: ${props => (props.centerText ? "center" : "left")};
  z-index: 2;
  @media (${device.tablet}) {
    margin-top: auto;
    margin-bottom: auto;
  } ;
`
