import styled from "styled-components"
import device from "../Theme/devices"
import { SectionTitle } from "../Texts"
import { colors } from "../Theme/colors"
export const Background = styled.div``

export const Container = styled.div`
  position: relative;
  padding: 12px 12px 28px 12px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;

  z-index: 2;
  img {
    height: auto;
  }
  ${Background} {
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 20px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.33s ease;
  }
  @media (${device.desktop}) {
    align-self: stretch;
  }
  @media (pointer: fine) {
    :hover {
      ${Background} {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
        @media (${device.desktop}) {
          width: calc(100% + 20px);
          height: calc(100% + 20px);
        }
      }
    }
  }
`

export const Title = styled(SectionTitle).attrs({ as: "h3" })`
  margin-top: 13px;
  margin-bottom: 2px;
`

export const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    margin: 8px 0;
  }
`

export const CardContentInner = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const CardContentPart = styled.div<{
  fullWidth: boolean
  textColor: "default" | "gray"
}>`
  flex: ${props => (props.fullWidth ? 100 : 50)}%;
  padding-right: ${props => (props.fullWidth ? 0 : 10)}px;
  color: ${props => props.textColor === "gray" && colors.gray};
`

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 23px;
  margin-top: auto;
  > :first-child {
    margin-left: auto;
  }
  > :last-child:not(:first-child) {
    margin-right: auto;
  }
`
