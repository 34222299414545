import { MarginsAndPaddingsRulesFn } from "./marginsAndPaddingsRules"
import { defaultMarginsAndPaddingsRules } from "./defaultRules"
import { css } from "styled-components"
import { articleMarginsAndPaddingsRules } from "./articleRules"

const emptyInterpolation = css``

const noRules = () => emptyInterpolation

export type RuleSet = "default" | "article" | "none"

export const marginsAndPaddingsRules: (
  rulesetName: RuleSet
) => MarginsAndPaddingsRulesFn = rulesetName => {
  switch (rulesetName) {
    case "none":
      return noRules
    case "article":
      return articleMarginsAndPaddingsRules
    case "default":
    default:
      return defaultMarginsAndPaddingsRules
  }
}
