import React, { useEffect } from "react"
import * as S from "./styled"
import { graphql } from "gatsby"
import { FormControl, FormProps } from "./Form"
import { Forms } from "../../../../components/FormComponents"
import { Button } from "../../../../components/Buttons"
import { FormLabel } from "../../../../components/Texts"
import {
  NetlifyHiddenInput,
  useNetlifyForm,
} from "../../../../hooks/useNetlifyForm"
import { UseFormMethods } from "react-hook-form"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
// @ts-ignore
import { useTriggerTransition } from "gatsby-plugin-transition-link"
import { defaultAnyLinkProps } from "../../../../components/DatoCmsAction"

export const Control: React.FC<
  FormControl & {
    register: UseFormMethods["register"]
  } & { showPlaceholder?: boolean }
> = ({ register, ...props }) => {
  const label = props.name + (props.required ? "*" : "")
  switch (props.__typename) {
    case "DatoCmsSingleLineText":
      return (
        <Forms.Label label={label}>
          <Forms.Input
            ref={register({ required: props.required })}
            name={props.name}
            type={props.valueType}
            placeholder={props.showPlaceholder ? props.name : ''}
          />
        </Forms.Label>
      )
    case "DatoCmsMultiLineText":
      return (
        <Forms.Label label={label}>
          <Forms.TextArea
            ref={register({ required: props.required })}
            name={props.name}
          />
        </Forms.Label>
      )
    case "DatoCmsDropdown":
      return (
        <>
          <Forms.Label label={label} />
          <Forms.Select
            //@ts-ignore
            ref={register({ required: props.required })}
            name={props.name}
            options={props.options?.split("\n")}
          />
        </>
      )
    case "DatoCmsCheckboxGroup":
      return (
        <Forms.Label label={label} forCheckboxes>
          <Forms.CheckboxGroup
            getRef={() => register({ required: props.required })}
            options={props.options.split("\n")}
            name={props.name}
          />
        </Forms.Label>
      )
    case "DatoCmsRadioGroup":
      return (
        <Forms.Label label={label} forCheckboxes>
          <Forms.RadioGroup
            getRef={() => register({ required: props.required })}
            options={props.options.split("\n")}
            name={props.name}
          />
        </Forms.Label>
      )
  }
}

export const Form: React.FC<
  FormProps & {
    halfWidth?: boolean
    isForThankYouPage?: boolean
    thankYouPage?: string
    thankYouPagePath?: string
  }
> = props => {
  const { register, submissionState, errors, onSubmit } = useNetlifyForm(
    props.name
  )
  const navigate = useTriggerTransition()
  useEffect(() => {
    if (submissionState === "ok") {
      navigate({ to: props.thankYouPagePath, ...defaultAnyLinkProps })
    }
  }, [submissionState, props.thankYouPagePath])

  useEffect(() => {
    //submissionState === "ok" && window.scrollTo({ top: 0, behavior: "smooth" })
  }, [submissionState])

  return !props.isForThankYouPage ? (
    <S.Container
      data-netlify="true"
      submissionState={submissionState}
      onSubmit={onSubmit}
      {...props}
    >
      {props.controls.map((control, i) => (
        <S.FormRow key={control.id} tabletRow={i + 1} row={i + 1}>
          <Control register={register} {...control} />
          <S.Error>{errors[control.name] && props.requiredFieldError}</S.Error>
        </S.FormRow>
      ))}
      <S.FormRow
        tabletRow={props.controls.length + 1}
        row={props.controls.length + 1}
      >
        <NetlifyHiddenInput formName={props.name} register={register} />
        {/*@ts-ignore*/}
        <Button type={"submit"}>{props.submitButtonLabel}</Button>
      </S.FormRow>
      <S.Hints
        tabletRow={props.controls.length + 2}
        row={props.controls.length + 2}
        as={FormLabel}
      >
        {props.requiredFieldsHint}
      </S.Hints>
    </S.Container>
  ) : (
    <S.MessageContainer>
      <S.FormRow
        tabletRow={1}
        row={1}
        as={DatoCmsHtmlText}
        html={props.thankYouMessage}
      />
    </S.MessageContainer>
  )
}

export const fragment = graphql`
  fragment FormData on DatoCmsForm {
    __typename
    id
    name
    submitButtonLabel
    requiredFieldsHint
    thankYouMessage
    requiredFieldError
    controls {
      __typename
      ... on DatoCmsSingleLineText {
        id
        name
        valueType
        required
      }
      ... on DatoCmsMultiLineText {
        id
        name
        required
      }
      ... on DatoCmsDropdown {
        id
        name
        options
        required
      }
      ... on DatoCmsRadioGroup {
        id
        name
        options
        required
      }
      ... on DatoCmsCheckboxGroup {
        id
        name
        options
        required
      }
    }
  }
`
