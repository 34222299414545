import React from "react"
import styled from "styled-components"

export const Input = styled.input`
  border: 1px solid #807f7a;
  box-sizing: border-box;
  background: none;
  outline: none;
  height: 58px;
  padding: 10px 20px;
  border-radius: 3px;
  transition: all 250ms ease-out;

  :focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
`
