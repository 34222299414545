import styled from "styled-components"
import { GridCell } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"

type ItemProps = {
  index: number
}

const rowNumber = (
  index: number,
  perColumn: number,
  rowCount: number,
  oddRow?: boolean
) => {
  ++rowCount
  const row = Math.ceil((index + 1) / perColumn) + 1
  const baseRow = Math.max(1, (row - 1) * rowCount) + (oddRow ? -1 : -2)
  const endRow = baseRow + rowCount

  return `${baseRow} / ${endRow}`
}

export const TitleCell = styled(GridCell)`
  margin-top: 0;
  margin-bottom: 21px;
`

export const DescriptionCell = styled(GridCell)`
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export const Item = styled(GridCell).attrs((props: ItemProps) => ({
  row: rowNumber(props.index, 3, 2, props.index % 3 === 1),
  tabletRow: rowNumber(props.index, 2, 2),
  column: "0",
  tabletCol: "0",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
}))<ItemProps>``

export const Container = styled(GridCell).attrs({
  row: 3,
  column: "3 / 13",
  tabletRow: 3,
  tabletCol: "2 / 8",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 50px;
  }

  @media (${device.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    > * {
      margin-bottom: 60px;
    }

    > :nth-child(2n - 1) {
      grid-column: 1;
    }

    > :nth-child(2n) {
      grid-column: 2;
    }
    && {
      > :nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }

  @media (${device.desktop}) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 70px;
    margin-top: 40px;

    > * {
      margin-bottom: 111px;
    }

    > :nth-child(3n-2) {
      grid-column: 1;
    }

    > :nth-child(3n-1) {
      grid-column: 2;
      transform: translateY(140px);
    }

    > :nth-child(3n) {
      grid-column: 3;
    }

    && {
      > :nth-last-child(-n + 3) {
        margin-bottom: 0;
        padding-bottom: 140px;
      }
    }
  }
`
