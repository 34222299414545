import React from "react"
import { graphql } from "gatsby"
import { ThreeTextColumnsProps } from "./ThreeTextColumns"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { Body2 } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import styled from "styled-components"

const Column = styled(GridCell)`
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  color: #818387;
`

export const TreeTextColumns: React.FC<ThreeTextColumnsProps> = ({
  text1,
  text2,
  text3,
  ...props
}) => (
  <GridLayout {...props}>
    <Column
      row={1}
      tabletRow={1}
      column={"3 / 6"}
      tabletCol={"2 / 4"}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      as={DatoCmsHtmlText}
      html={text1}
      forwardedAs={Body2}
    />
    <Column
      row={1}
      tabletRow={1}
      column={"6 / 9"}
      tabletCol={"4 / 6"}
      as={DatoCmsHtmlText}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      html={text2 || ""}
      forwardedAs={Body2}
    />
    <Column
      row={1}
      tabletRow={2}
      column={"9 / 13"}
      tabletCol={"2 / 6"}
      as={DatoCmsHtmlText}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      html={text3 || ""}
      forwardedAs={Body2}
    />
  </GridLayout>
)

export const fragment = graphql`
  fragment ThreeTextColumnsData on DatoCmsTextTreeColumn {
    id
    text1
    text2
    text3
    greenish
  }
`
