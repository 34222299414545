import React, { useCallback, useRef, useState } from "react"
import { Input } from "./Input"
import styled from "styled-components"
//@ts-ignore
import { ReactComponent as Arrow } from "../../vector/select-arrow.svg"
import { colors } from "../Theme/colors"
import { useMedia } from "use-media"
import { size } from "../Theme/devices"

type SelectProps = {
  options: string[]
  name: string
}

const SelectComponent = styled(Input).attrs({ as: "select" })`
  appearance: none;
  width: 100%;
`

const Container = styled.div`
  position: relative;
`

const DropArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;

  width: 35px;
  height: 35px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
  background: ${colors.veryPaleGreen};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  cursor: pointer;
`

const DesktopSelectWrapper = styled.div`
  input:focus + div,
  div:focus-within {
    max-height: 550px;
    opacity: 1;
    overflow-y: auto;
  }
`

const DesktopSelect = styled(Input).attrs({ readOnly: true })`
  && {
    width: 100%;
    :focus {
      box-shadow: none;
      border-bottom: none;
    }
  }
`

const DesktopOptionBox = styled(Input).attrs({ as: "div" })`
  && {
    position: absolute;
    top: 0;
    transition: all 250ms ease-out;
    max-height: 0;
    opacity: 0;
    z-index: 10;
    height: auto;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
`

const DesktopOption = styled.button<{ selected: boolean }>`
  border: none;
  outline: none;
  min-height: 45px;
  display: flex;
  align-items: center;
  :first-child {
    margin-top: 58px;
  }
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${props =>
    props.selected ? "#EBE9DE !important" : "white"};
  :hover,
  :focus {
    background-color: #f6f5ef;
  }
`

export const Select: React.FC<SelectProps> = React.forwardRef(
  ({ options, ...props }, ref) => {
    const isDesktop = useMedia({ minWidth: size.desktop })
    const selectRef = useRef<HTMLSelectElement>()
    const [, reRender] = useState<boolean>(false)
    const selectOptionDesktop = useCallback(
      (option: string) => (e: Event) => {
        // @ts-ignore
        selectRef.current.value = option
        e.preventDefault()
        reRender(r => !r)
      },
      []
    )
    return (
      <Container>
        <SelectComponent
          style={{ display: isDesktop ? "none" : undefined }}
          name={props.name}
          //@ts-ignore
          ref={r => (selectRef.current = r) && ref(r)}
        >
          <option hidden disabled selected value={""} />
          {options.map(o => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </SelectComponent>
        {isDesktop && (
          <DesktopSelectWrapper>
            <DesktopSelect name={props.name} value={selectRef.current?.value} />
            <DesktopOptionBox>
              {options.map(o => (
                <DesktopOption
                  tabIndex={0}
                  key={o}
                  selected={o === selectRef.current?.value}
                  //@ts-ignore
                  onClick={selectOptionDesktop(o)}
                >
                  {o}
                </DesktopOption>
              ))}
            </DesktopOptionBox>
          </DesktopSelectWrapper>
        )}
        <DropArrow>
          <Arrow />
        </DropArrow>
      </Container>
    )
  }
)
