import React, { useState } from "react"
import { graphql } from "gatsby"
import * as S from "./styled"
import { DescriptionPlusStoryProps } from "./DescriptionPlusStory"
import { Body16, SectionTitle } from "../../../../components/Texts"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import { GridLayoutProps } from "../../../../components/GridLayout/GridLayout"
import { StoryThumbnail } from "../../../../components/StoryThumbnail"
import { SlideInTextAnimation } from "../../../../components/SlideInTextAnimation"
import { useImageSlideInSpring } from "../../../../hooks/useImageSlideinSpring"
import { animated } from "react-spring"

export const DescriptionPlusStory: React.FC<
  DescriptionPlusStoryProps & GridLayoutProps
> = props => {
  const [titleRested, setTitleRested] = useState<boolean>(false)
  const [descriptionRested, setDescriptionRested] = useState<boolean>(false)
  const { ref: actionRef, spring: actionSpring } = useImageSlideInSpring({
    pause: props.description ? !descriptionRested : !titleRested,
  })
  return (
    <S.Wrapper {...props}>
      <S.TextWrapper row={1} tabletRow={1} column="3 / 6">
        <SectionTitle>
          <SlideInTextAnimation
            text={props.title}
            onRest={() => setTitleRested(true)}
          />
        </SectionTitle>
      </S.TextWrapper>
      <S.TextWrapper row={2} tabletRow={2} column="3 / 7" as={Body16}>
        <SlideInTextAnimation
          text={props.description}
          onRest={() => setDescriptionRested(true)}
        />
      </S.TextWrapper>
      <S.ActionWrapper ref={actionRef}>
        <animated.div style={actionSpring}>
          <DatoCmsAction {...props.action} />
        </animated.div>
      </S.ActionWrapper>

      <S.StoryCell>
        <StoryThumbnail
          {...props.story}
          animateInView
          thumbnailImage={
            props.alternativeStoryThumbnailImage || props.story.thumbnailImage
          }
        />
      </S.StoryCell>
    </S.Wrapper>
  )
}

export const fragment = graphql`
  fragment DescriptionPlusStoryData on DatoCmsDescriptionPlusStory {
    __typename
    id
    title
    description
    story {
      ...StoryThumbnail
    }
    alternativeStoryThumbnailImage {
      ...DatoCmsAssetData
    }
    action {
      ...ActionData
    }
    greenish
  }
`
