import React from "react"
import { graphql } from "gatsby"
import { TextProps } from "./Text"
import { GridLayout, GridCell } from "../../../../components/GridLayout"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { SectionTitle } from "../../../../components/Texts"
import styled from "styled-components"

const Container = styled(GridLayout)`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  h1,
  h2,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    font-weight: 400;

    + p {
      margin-top: 0;
    }
  }

  h2 {
    margin-top: 1em;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: underline;
  }
`

const TextCell = styled(GridCell)`
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

export const Text: React.FC<TextProps> = ({ text, ...props }) => (
  <Container padTop padBottom {...props}>
    <TextCell
      as={DatoCmsHtmlText}
      column={"5 / 11"}
      row={1}
      tabletRow={1}
      tabletCol={"3 / 7"}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      html={text}
      replace={{
        h2: SectionTitle,
      }}
    />
  </Container>
)

export const fragment = graphql`
  fragment TextData on DatoCmsText {
    id
    text
    greenish
  }
`
