import React from "react"
import styled, { css } from "styled-components"

const LabelComponent = styled.label<{ forCheckboxes?: boolean }>`
  > * {
    width: 100%;
  }

  ${props =>
    props.forCheckboxes &&
    css`
      pointer-events: none;

      > *:not(.title) {
        pointer-events: auto;
      }
    `}
`

const Title = styled.div.attrs({
  className: "title",
})`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 5px;

  color: #807f7a;
`

type LabelProps = {
  label: string
  forCheckboxes?: boolean
}

export const Label: React.FC<LabelProps> = props => (
  <LabelComponent forCheckboxes={props.forCheckboxes}>
    <Title>{props.label}</Title>
    {props.children}
  </LabelComponent>
)
