import React from "react"
import styled, { css } from "styled-components"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { MessagesContainer } from "../../../../components/Messages/MessagesContainer"
import { Body16, SectionTitle } from "../../../../components/Texts"
import device from "../../../../components/Theme/devices"
import type { DescriptionPlusMessagingProps } from "./descriptionPlusMessaging"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"

export const TextContainer = styled(GridCell).attrs(props => ({
  row: 1,
  tabletRow: 1,
  //@ts-ignore
  column: props.empty ? "3 / 10" : "3 / 8",
  tabletCol: " 2 / 6",
}))<{ empty: boolean }>`
  order: 10;
  margin-top: 0;
  padding: 15px 20px;
  white-space: pre-wrap;
  @media (${device.desktop}) {
    padding: 0 5px 0 0;
    ${props =>
      props.empty &&
      css`
        padding-right: 40px;
      `};
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 0;
    @media (${device.tablet}) {
      margin-top: 0;
    }
  }
`

export const ActionContainer = styled(TextContainer)`
  grid-row: 2;
  order: 11;
  padding-bottom: 0;
  @media (${device.tablet}) {
    margin-top: 40px;
  }
`

export const MessagesContainerDiv = styled(GridCell).attrs({
  row: "1 / 3",
  tabletRow: "1 / 3",
  column: "8 / 13",
  tabletCol: "6 / 9",
  rightMargin: "mobile",
  leftMargin: "mobile",
})<{}>`
  @media (${device.desktop}) {
    margin-left: 58px;
    margin-top: auto;
    margin-bottom: 8px;
  }
`

export const MessagingWithDescription: React.FC<DescriptionPlusMessagingProps> = props => (
  <GridLayout {...props}>
    <TextContainer
      empty={
        !props.messaging?.messages || props.messaging.messages.length === 0
      }
    >
      <SectionTitle>{props.text}</SectionTitle>
      {props.description && (
        <DatoCmsHtmlText as={Body16} html={props.description} />
      )}
    </TextContainer>
    {props.action && (
      <ActionContainer
        empty={
          !props.messaging?.messages || props.messaging.messages.length === 0
        }
      >
        <DatoCmsAction {...props.action} />
      </ActionContainer>
    )}
    {props.messaging?.messages && props.messaging.messages.length > 0 && (
      <MessagesContainer
        dontAnimate
        //@ts-ignore
        as={MessagesContainerDiv}
        {...props.messaging}
      />
    )}
  </GridLayout>
)
