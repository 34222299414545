import { MarginsAndPaddingsRulesFn } from "./marginsAndPaddingsRules"
import { basicMargins, isGreenish, marginsCss } from "./base"

export const articleMarginsAndPaddingsRules: MarginsAndPaddingsRulesFn = (
  block,
  before,
  after
) => {
  const base = basicMargins(block, before, after)

  if (!before) {
    // On articles, the first content item is right below the header
    base.paddingTop = undefined
  }

  switch (block.__typename) {
    case "DatoCmsMarquee":
      base.paddingTop = { phone: 20, tablet: 104, desktop: 104 }
      base.paddingBottom = base.paddingTop
      break
    case "DatoCmsImage":
      if (block.onlyPartlyGreenish && !isGreenish(after)) {
        base.paddingBottom = { phone: 10, tablet: 30, desktop: 40 }
      }
      break
  }

  return marginsCss(base)
}
