import React from "react"
import { graphql, Link } from "gatsby"
import { JobTableProps } from "./JobTable"
import { SectionTitle } from "../../../../components/Texts"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import * as S from "./styled"
import { IconButton } from "../../../../components/Buttons"
//@ts-ignore
import { ReactComponent as Arrow } from "../../../../vector/ic-arrow-right.svg"
import { JobVacancyThumbnail } from "../../../../types/JobVacancy"
import {
  ConfigurationContextType,
  useConfiguration,
} from "../../../../context/configuration"
import {
  DatoCmsAction,
  parseDatoCmsPageLink,
} from "../../../../components/DatoCmsAction"

export const JobTable: React.FC<JobTableProps> = ({
  title,
  vacancies,
  footerAction,
  ...props
}) => {
  const config = useConfiguration()
  return (
    <GridLayout {...props}>
      <GridCell
        row={1}
        column={"3 / 8"}
        tabletRow={1}
        tabletCol={"2 / 7"}
        leftMargin={"phone-only"}
        rightMargin={"phone-only"}
        as={SectionTitle}
      >
        {title}
      </GridCell>
      <GridCell
        row={2}
        column={"3 / 13"}
        tabletRow={2}
        tabletCol={"2 / 8"}
        leftMargin={"phone-only"}
        rightMargin={"phone-only"}
        as={S.VacancyTable}
      >
        {vacancies.map(v => (
          <VacancyRow key={v.id} config={config} {...v} />
        ))}
      </GridCell>
      {footerAction && (
        <GridCell
          row={3}
          column={"3/13"}
          tabletRow={3}
          tabletCol={"2/8"}
          leftMargin={"phone-only"}
          rightMargin={"phone-only"}
          as={S.ActionContainer}
        >
          <DatoCmsAction {...footerAction} />
        </GridCell>
      )}
    </GridLayout>
  )
}

const VacancyRow: React.FC<
  JobVacancyThumbnail & { config: ConfigurationContextType }
> = props => {
  const link = parseDatoCmsPageLink(props, props.config)
  return (
    <S.VacancyRow as={link?.LinkComp || undefined} {...(link.linkProps || {})}>
      <S.Title>{props.tableTitle}</S.Title>
      <S.JobType>{props.tableJobType}</S.JobType>
      <S.ButtonContainer>
        <IconButton>
          <Arrow />
        </IconButton>
      </S.ButtonContainer>
    </S.VacancyRow>
  )
}

export const fragment = graphql`
  fragment JobTableData on DatoCmsJobTable {
    id
    title
    vacancies {
      ...JobVacancyRowData
    }
    footerAction {
      ...ActionData
    }
  }
`
