import { MarginsAndPaddingsValues } from "../../../components/GridLayout/GridLayout"
import { css } from "styled-components"
import device from "../../../components/Theme/devices"
import { BlockMeta } from "../DatoCmsContent"

export const DEFAULT_PADDING_PHONE = 40
export const SMALLER_PADDING_PHONE = 30

export const DEFAULT_PADDING_TABLET = 80
export const SMALLER_PADDING_TABLET = 60

export const DEFAULT_PADDING_DESKTOP = 80
export const SMALLER_PADDING_DESKTOP = 60

export const marginsCss = (values: MarginsAndPaddingsValues) => css`
  padding-top: ${values.paddingTop?.phone || 0}px;
  padding-bottom: ${values.paddingBottom?.phone || 0}px;
  margin-top: ${values.marginTop?.phone || 0}px;
  margin-bottom: ${values.marginBottom?.phone || 0}px;

  @media (${device.tablet}) {
    padding-top: ${values.paddingTop?.tablet || 0}px;
    padding-bottom: ${values.paddingBottom?.tablet || 0}px;
    margin-top: ${values.marginTop?.tablet || 0}px;
    margin-bottom: ${values.marginBottom?.tablet || 0}px;
  }

  @media (${device.desktop}) {
    padding-top: ${values.paddingTop?.desktop || 0}px;
    padding-bottom: ${values.paddingBottom?.desktop || 0}px;
    margin-top: ${values.marginTop?.desktop || 0}px;
    margin-bottom: ${values.marginBottom?.desktop || 0}px;
  }
`

export const isGreenish = (block?: BlockMeta) =>
  block?.greenish || block?.__typename.includes("Header")

export const basicMargins: (
  block: BlockMeta,
  before?: BlockMeta,
  after?: BlockMeta
) => MarginsAndPaddingsValues = (block, before, after) => {
  const result = {} as MarginsAndPaddingsValues

  const defaultPadding = { phone: 40, tablet: 80, desktop: 80 }
  const smallerPadding = { phone: 30, tablet: 60, desktop: 60 }

  if (!before) {
    // First item, should get a margin of 250px on desktop,
    result.paddingTop = { phone: 30, tablet: 150, desktop: 250 }
  } else if (isGreenish(before) === isGreenish(block)) {
    // Between two greenish (or plain) sections, add a smaller padding
    result.paddingTop = smallerPadding
  } else {
    // One of them isn't greenish, use default padding
    result.paddingTop = defaultPadding
  }

  if (!after) {
    // Last item, will get the same margins as the last pillset on homepage
    result.paddingBottom = { phone: 60, tablet: 90, desktop: 90 }
  } else if (isGreenish(block) === isGreenish(after)) {
    // Between two greenish (or plain) sections, add a smaller padding
    result.paddingBottom = smallerPadding
  } else {
    // One of them isnt't greenish, use default padding
    result.paddingBottom = defaultPadding
  }

  return result
}
