import React from "react"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { MessagesContainer } from "../../../../components/Messages/MessagesContainer"
import styled from "styled-components"
import { Body16, SectionTitle } from "../../../../components/Texts"
import device from "../../../../components/Theme/devices"
import { DescriptionPlusMessagingProps } from "./descriptionPlusMessaging"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"

const Wrapper = styled(GridLayout)`
  grid-template-rows: auto auto 1fr;
`

const TextWrapper = styled(GridCell)`
  h2 {
    margin-top: 0;
  }
  @media (${device.tablet}) {
    margin-left: 0;
  }

  h2 {
    margin-bottom: 25px;
  }
  p:first-of-type {
    margin-top: 0;
  }

  @media (${device.desktop}) {
    &:first-child {
      padding-right: 40px;
    }
  }
`

const ActionWrapper = styled(TextWrapper)`
  margin-top: 8px;
`

const MessagesContainerComponent = styled(MessagesContainer)`
  margin-top: 60px;
  @media (${device.desktop}) {
    margin-top: -130px;
  }
`

export const ImageMessages: React.FC<DescriptionPlusMessagingProps> = ({
  text,
  messaging,
  description,
  action,
  ...props
}) => (
  <Wrapper {...props}>
    <TextWrapper
      row="1"
      column="3 / 6"
      tabletRow={1}
      tabletCol="2 / 8"
      leftMargin="mobile"
      rightMargin={"mobile"}
    >
      <SectionTitle>{text}</SectionTitle>
    </TextWrapper>
    <TextWrapper
      as={DatoCmsHtmlText}
      row="2"
      tabletRow={2}
      column="3 / 7"
      tabletCol={"2 / 8"}
      leftMargin={"mobile"}
      rightMargin={"mobile"}
      forwardedAs={Body16}
      html={description || ""}
    />
    <ActionWrapper
      row="3"
      column="3 / 7"
      tabletRow={3}
      tabletCol={"2 / 8"}
      leftMargin={"mobile"}
      rightMargin={"mobile"}
    >
      {action && <DatoCmsAction {...action} />}
    </ActionWrapper>
    <GridCell
      row={"1 / 4"}
      tabletRow={4}
      tabletCol={"2 / 8"}
      column={"8 / 14"}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
    >
      {messaging && (
        <MessagesContainerComponent
          messages={messaging.messages}
          conversationType={"staggered"}
        />
      )}
    </GridCell>
  </Wrapper>
)
