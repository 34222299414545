import React from "react"
import { graphql } from "gatsby"
import { CardSetProps } from "./CardSet"
import { GridLayout } from "../../../../components/GridLayout"
import {
  Card,
  CardActionWrapper,
  CardContentPart,
  CardContentWrapper,
} from "../../../../components/Card"
import * as S from "./styled"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import {
  DatoCmsAction,
  parseDatoCmsAction,
} from "../../../../components/DatoCmsAction"
import { useConfiguration } from "../../../../context/configuration"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"
//@ts-ignore
import { ReactComponent as Arrow } from "../../../../vector/ic-arrow-right.svg"

export const CardSet: React.FC<CardSetProps> = props => {
  let CardWrapper: React.FC
  switch (props.cardLayout) {
    case "staggered":
      CardWrapper = S.StaggeredCardContainer
      break
    case "horizontal":
    default:
      CardWrapper = S.CardContainer
      break
  }

  const config = useConfiguration()

  return (
    <GridLayout {...props}>
      <S.Title>{props.title}</S.Title>
      <CardWrapper
        //@ts-ignore
        sectionMargins={props.sectionMargins}
        //@ts-ignore
        spacingBetweenCards={props.spacingBetweenCards}
      >
        {props.cards.map(card => (
          <Card
            key={card.id}
            title={card.title}
            imageElement={card.image && <DatoCmsAsset {...card.image} />}
            //@ts-ignore
            style={{ textDecoration: "none" }}
            as={
              card.action && card.hideActionButton
                ? parseDatoCmsAction(card.action, config).Component
                : undefined
            }
            {...(card.action && card.hideActionButton
              ? parseDatoCmsAction(card.action, config).props
              : {})}
          >
            <CardContentWrapper>
              {card.content.map(c => (
                <DatoCmsHtmlText
                  key={c.id}
                  as={CardContentPart}
                  html={c.text}
                  {...c}
                />
              ))}
            </CardContentWrapper>
            {card.action && !card.hideActionButton && (
              <CardActionWrapper>
                <DatoCmsAction {...card.action} />
              </CardActionWrapper>
            )}
          </Card>
        ))}
      </CardWrapper>
      <S.ActionContainer>
        {props.action && (
          <DatoCmsAction
            minimal
            {...props.action}
            label={
              props.action.style === "secondary" ? (
                <>
                  {'> '}
                  {props.action.label}
                </>
              ) : (
                props.action.label
              )
            }
          />
        )}
      </S.ActionContainer>
    </GridLayout>
  )
}

export const fragment = graphql`
  fragment CardSetData on DatoCmsCardSet {
    id
    title
    cardLayout
    cards {
      id
      title
      image {
        ...DatoCmsAssetData
      }
      content {
        id
        fullWidth
        textColor
        text
      }
      action {
        ...ActionData
      }
      hideActionButton
    }
    action {
      ...ActionData
    }
    spacingBetweenCards
    sectionMargins
    greenish
  }
`
