import React, { useMemo } from "react"
import { StoryThumbnail as IStoryThumbnail } from "../../types/story"
import {
  MessageBubbleSpring,
  useMessageBubbleSprings,
} from "../Messages/MessageBubble.springs"
import { MessageBubble } from "../Messages/MessageBubble"
import { useReadMoreCursor } from "../../context/readMoreCursor"
import { useInView } from "react-intersection-observer"

type StoryThumbnailProps = IStoryThumbnail & {
  atLeftSide?: boolean
  springs?: MessageBubbleSpring
  animateInView?: boolean
  [other: string]: any
}
const StoryThumbnailComponent: React.FC<StoryThumbnailProps> = ({
  thumbnailHour,
  thumbnailDescription,
  thumbnailAuthor,
  thumbnailImage,
  ...props
}) => {
  const { setVisible: setReadMoreVisible } = useReadMoreCursor()
  const containerProps = useMemo(
    () => ({
      onMouseEnter: () => setReadMoreVisible(true),
      onMouseLeave: () => setReadMoreVisible(false),
    }),
    []
  )

  const pageLink = useMemo(
    () => ({ path: props.path, __typename: props.__typename }),
    [props.path, props.__typename]
  )
  const { ref, inView } = useInView({ triggerOnce: true })
  const springs = useMessageBubbleSprings([{ image: thumbnailImage }], inView)

  return (
    <MessageBubble
      ref={ref}
      springs={props.animateInView ? springs[0] : props.springs}
      author={thumbnailAuthor}
      message={thumbnailDescription}
      hour={thumbnailHour}
      image={thumbnailImage}
      pageLink={pageLink}
      {...props}
      {...containerProps}
    />
  )
}

export const StoryThumbnail = React.memo<StoryThumbnailProps>(props => (
  <StoryThumbnailComponent {...props} />
))
