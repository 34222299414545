import styled from "styled-components"
import device from "../../../../components/Theme/devices"

export const VacancyTable = styled.div`
  display: flex;
  flex-direction: column;
`

export const VacancyRow = styled.div`
  width: 100%;
  border-bottom: 1px solid;
  :first-child {
    border-top: 1px solid;
  }

  display: grid;
  grid-column-gap: 20px;
  align-items: center;
  padding: 20px 0;
  grid-template-rows: 1fr auto;

  @media (${device.tablet}) {
    grid-template-columns: repeat(5, 1fr);
  }
  cursor: pointer;
  :hover {
    text-decoration: underline;
    button {
      transform: translateX(20px);
    }
  }
`

export const Title = styled.div`
  grid-row: 1;
  grid-column: 1;
  @media (${device.tablet}) {
    grid-column: 1 / 4;
  }
`

export const JobType = styled.div`
  grid-row: 2;
  grid-column: 1;
  margin-top: 10px;
  @media (${device.tablet}) {
    grid-row: 1;
    grid-column: 4;
    margin-top: 0;
  }
`

export const ButtonContainer = styled.div`
  grid-row: 1 / 3;
  grid-column: 2;
  margin-bottom: auto;
  @media (${device.tablet}) {
    grid-row: 1;
    grid-column: 5;
  }

  button {
    margin-left: auto;
    transition: all 250ms ease-out;
  }
`

export const ActionContainer = styled.div`
  margin-top: 20px;
  margin-left: auto;
`
