import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import device from "../Theme/devices"
import type { MessageBubbleType } from "./messages"
import { animated } from "react-spring"
import { MessageBubbleSpring } from "./MessageBubble.springs"
import { parseDatoCmsPageLink } from "../DatoCmsAction"
import { useConfiguration } from "../../context/configuration"
import { Parallax } from "react-scroll-parallax"
import { DatoCmsAsset } from "../DatoCmsAsset"

const Wrapper = styled.div<{ hasLink?: boolean }>`
  ${props =>
    props.hasLink &&
    css`
      && {
        cursor: none;
        text-decoration: none;
        :visited,
        :focus {
          outline: none;
        }
      }
    `}
`

const Container = styled(animated.div)<{
  leftSide: boolean
  withImage?: boolean
}>`
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  z-index: 2;
  transition: scale 450ms ease-in-out;
  ${props =>
    props.leftSide
      ? css`
          transform-origin: bottom left;
          border-bottom-left-radius: 0;
        `
      : css`
          border-bottom-right-radius: 0;
          transform-origin: bottom right;
        `};

  ${props =>
    props.withImage
      ? css`
          padding: 13px 19px 10px 23px;
          line-height: 18px;
          @media (${device.tablet}) {
            max-width: 340px;
            box-sizing: border-box;
          }
        `
      : css`
          padding: 19px 26px 15px 30px;
          @media (${device.tablet}) {
            padding: 20px 26px 18px 30px;
          }
          line-height: 22px;
        `};

  color: ${props => props.theme.textColorLighter};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  ${props =>
    props.withImage &&
    css`
      margin-right: 10px;
      margin-left: -10px;
      @media (${device.desktop}) {
        margin-right: 30px;
        margin-left: -30px;
      }
    `}
`

export const Sender = styled(animated.div)<{
  alignLeft?: boolean
  withImage?: boolean
}>`
  font-family: Roboto, sans-serif;
  color: ${props => props.theme.textColorLightest};
  text-align: ${props => (props.alignLeft ? "left" : "right")};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  padding-top: 8px;

  ${props =>
    props.withImage &&
    css`
      margin-right: 10px;
      margin-left: -10px;
      @media (${device.desktop}) {
        margin-right: 30px;
        margin-left: -30px;
      }
      @media (${device.tablet}) {
        max-width: 340px;
      }
    `}
`

export const ImageContainer = styled(animated.div)`
  box-shadow: 0 0 20px rgba(134, 130, 115, 0.3);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
  margin-bottom: -52px;
  transition: margin 250ms ease-out;
  :hover {
    margin-left: -30px;
    margin-right: -30px;

    > * {
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const Asset = styled(animated(DatoCmsAsset))`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-left: -30px;
  margin-right: -30px;
  transition: margin 250ms ease-out;
`

type MessageBubbleProps = MessageBubbleType & {
  imageContainerProps?: any
  springs?: MessageBubbleSpring
}

const MessageBubbleComponent: React.FC<MessageBubbleProps> = React.forwardRef(
  (
    { atLeftSide, author, hour, message, image, pageLink, springs, ...props },
    ref
  ) => {
    const config = useConfiguration()
    const link = pageLink && parseDatoCmsPageLink(pageLink, config)
    return (
      <Wrapper
        innerRef={ref}
        className={atLeftSide ? "left" : "right"}
        hasLink={!!link}
        as={link?.LinkComp || undefined}
        {...(link?.linkProps || undefined)}
        {...props}
      >
        {image && (
          <Parallax className="custom-class" y={[5, -5]}>
            <ImageContainer
              style={{
                //@ts-ignore
                boxShadow: springs?.imageShadowRadius?.to(
                  r => `0 0 ${r}px rgba(134, 130, 115, 0.3)` || undefined
                ),
              }}
              {...(props.imageContainerProps || {})}
            >
              <Asset
                style={{
                  //@ts-ignore
                  opacity: springs?.imageOpacity || undefined,
                  transform: springs?.imageTransform || undefined,
                }}
                {...image}
              />
            </ImageContainer>
          </Parallax>
        )}
        <Container
          leftSide={atLeftSide || false}
          withImage={!!image}
          style={{ scale: springs?.bubbleScale || 1 }}
        >
          {message}
        </Container>
        <Sender
          alignLeft={atLeftSide}
          withImage={!!image}
          //@ts-ignore
          style={{ opacity: springs?.senderOpacity || 1 }}
        >
          {author}
        </Sender>
      </Wrapper>
    )
  }
)

export const MessageBubble = React.memo<MessageBubbleProps & { ref?: any }>(
  forwardRef((props, ref) => <MessageBubbleComponent ref={ref} {...props} />)
)
