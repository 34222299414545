import React from "react"
import TypewriterComponent from "typewriter-effect"
import { useAfterLoading } from "../../context/loading"

export const Typewriter: React.FC<{ sentences: string[] }> = props => {
  const loaded = useAfterLoading()
  return (
    //@ts-ignore
    <TypewriterComponent
      options={{
        autoStart: loaded,
        strings: props.sentences,
        loop: true,
        delay: 60,
        deleteSpeed: 15,
      }}
    />
  )
}
