import React, { useState } from "react"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { Body2, PageTitle, SectionTitle } from "../../../../components/Texts"
import { PageHeaderProps } from "./PageHeader"
import styled from "styled-components"
import device from "../../../../components/Theme/devices"
import { SlideInTextAnimation } from "../../../../components/SlideInTextAnimation"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"

const Container = styled(GridLayout)`
  grid-template-rows: 1fr auto 0.5fr;
`

const ImageCell = styled(GridCell).attrs({
  row: "1 / 4",
  column: "8 / 15",
  tabletRow: "1 / 4",
  tabletCol: "5 / 9",
})`
  overflow: hidden;
  margin-top: 20px;
  @media (${device.tablet}) {
    margin-top: 0;
  }
`

const DescriptionCell = styled(GridCell)`
  padding-right: 2px;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const TitleCell = styled(GridCell)<{ withImage: boolean }>`
  margin-top: 0;
  margin-bottom: 17px;

  @media (${device.tablet}) {
    margin-top: auto;
  }

  @media (${device.desktop}) {
    margin-bottom: ${props => (props.withImage ? 27 : 59)}px;
  }
`

export const RightImage: React.FC<PageHeaderProps> = props => {
  const [titleRested, setTitleRested] = useState<boolean>(false)
  const [, setDescriptionRested] = useState<boolean>(false)
  return (
    <Container greenish={props.greenish} {...props}>
      <TitleCell
        row={1}
        column={props.image ? "3 / 7" : "3 / 12"}
        tabletRow={1}
        tabletCol={"2 / 5"}
        rightMargin={"phone-only"}
        leftMargin={"phone-only"}
        as={props.titleStyle !== "smaller" ? PageTitle : SectionTitle}
        withImage={!!props.image}
        huge={props.titleStyle === "huge"}
      >
        <SlideInTextAnimation
          text={props.title}
          onRest={() => setTitleRested(true)}
        />
      </TitleCell>
      <DescriptionCell
        row={2}
        column={"3 / 7"}
        tabletRow={2}
        tabletCol={" 2 / 5"}
        rightMargin={"phone-only"}
        leftMargin={"phone-only"}
        as={Body2}
      >
        <SlideInTextAnimation
          text={props.description}
          pause={!titleRested}
          onRest={() => setDescriptionRested(true)}
        />
      </DescriptionCell>
      {props.image && (
        <ImageCell>
          <DatoCmsAsset {...props.image} />
        </ImageCell>
      )}
    </Container>
  )
}
