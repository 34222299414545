import React from "react"
import { graphql } from "gatsby"
import type { DescriptionPlusImageProps } from "./DescriptionPlusImage"
import { GridLayout } from "../../../../components/GridLayout"
import * as S from "./styled"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { SectionTitle } from "../../../../components/Texts"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"

export const DescriptionPlusImage: React.FC<DescriptionPlusImageProps> = ({
  description,
  centerText,
  image,
  overlay,
  ...props
}) => (
  <GridLayout padTop padBottom {...props}>
    <S.DescriptionCell
      overlay={overlay}
      centerText={centerText}
      as={DatoCmsHtmlText}
      html={description}
      forwardedAs={SectionTitle}
      leftMargin={"all"}
      rightMargin={"all"}
    />
    <S.ImagCell overlay={overlay}>
      {image && <DatoCmsAsset {...image} />}
    </S.ImagCell>
  </GridLayout>
)

export const fragment = graphql`
  fragment DescriptionPlusImageData on DatoCmsDescriptionPlusImage {
    description
    centerText
    image {
      ...DatoCmsAssetData
    }
    overlay
    greenish
    onlyPartlyGreenish
  }
`
