import styled, { css } from "styled-components"
import { colors } from "../../../../components/Theme/colors";
import { GridLayout } from "../../../../components/GridLayout";
import device from "../../../../components/Theme/devices";

export const Container = styled.div``
export const Error = styled.div``
export const InputContainer = styled.div``

export const ChatFormWrapper = styled(GridLayout)`
  padding-top: 30px;
  padding-bottom: 90px;
  @media (${device.tablet}) {
    padding-bottom: 170px;
  }
`;

export const ContentColumn = styled.div`
  position: relative;
  @media (${device.tablet}) {
    grid-column: 3 / 7;
  }
  @media (${device.desktop}) {
    grid-column: 6 / 10;
  }
  @media (${device.phone}) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

export const Media = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 1em;
  position: relative;
  video, img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BubbleColumn = styled.div`
  grid-column: 2;
`;

export const Bubble = styled.div`
  background: white;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 26px;
  overflow: hidden;
  padding: 0.66em 1em;
  line-height: 1.5em;
  display: inline-flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: 14px;
  font-weight: 400;
  p {
    margin: 0;
  }
  svg {
    vertical-align: middle;
  }
`;

export const Form = styled.form`
    display: grid;
    grid-template-columns: ${40 + 24}px auto;
`;

export const ChatInputInline = styled.div`
  padding-top: 10px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 526px;
`;

export const Loading = styled.span<{ running: boolean }>`
  display: flex;
  gap: 5px;
  height: 1.5em;
  align-items: center;
  padding: 0 0.5em;
  span {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${colors.gray};
    opacity: 0.5;
    transform: scale(0.8);
  }
  ${(props) =>
    props.running &&
    css`
      > span:nth-of-type(1) {
        animation: 0.6s dotAnimation ease infinite;
        animation-delay: -0.6s;
      }
      > span:nth-of-type(2) {
        animation: 0.6s dotAnimation ease infinite;
        animation-delay: -0.4s;
      }
      > span:nth-of-type(3) {
        animation: 0.6s dotAnimation ease infinite;
        animation-delay: -0.2s;
      }
    `}
  @keyframes dotAnimation {
    0% {
      opacity: 1;
      transform: scale(0.8);
    }
    50% {
      opacity: 0.5;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(0.8);
    }
  }
`;

export const StyledVisual = styled.div`
  aspect-ratio: 1 / 1;
  img,
  video {
    width: 100%;
    height: auto;
  }
`;
