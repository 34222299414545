import React from "react"
import { GridCell, GridLayout } from "../GridLayout"
import MarqueeComponent from "react-fast-marquee"
import styled from "styled-components"
import { GridLayoutProps } from "../GridLayout/GridLayout"
import { useInView } from "react-intersection-observer"

type MarqueeProps = {
  text: string
  speed?: number
  textStyleComponent?: React.FC
  forwardedAs?: any
  repeat?: boolean
} & GridLayoutProps

const Container = styled(GridLayout)`
  > div {
    max-width: 100%;
  }
`

export const Marquee: React.FC<MarqueeProps & any> = ({
  text,
  speed = 0.1,
  textStyleComponent: TextStyle,
  ...props
}) => {
  const { ref, inView } = useInView()
  return (
    <Container ref={ref} {...props}>
      <GridCell row={1} column={"1 / 15"} tabletCol={"1 / 9"} tabletRow={1}>
        <TextStyle as={"div"} style={{ width: "100%", whiteSpace: "nowrap" }}>
          <MarqueeComponent
            speed={speed * 1000}
            delay={0}
            direction={"left"}
            play={inView}
            gradient={false}
          >
            <div style={{ margin: "0 50px" }}>{text}</div>
          </MarqueeComponent>
        </TextStyle>
      </GridCell>
    </Container>
  )
}
