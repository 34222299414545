import styled from "styled-components"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"

export const Wrapper = styled(GridLayout)`
  grid-template-rows: auto auto 1fr;

  @media (${device.tablet}) {
    padding-top: 60px !important;
  }
  @media (${device.desktop}) {
    padding-top: 80px !important;
  }
`

export const TextWrapper = styled(GridCell).attrs({
  leftMargin: "mobile",
  rightMargin: "mobile",
  tabletCol: "2 / 5",
})`
  h2 {
    margin-top: 0;
  }
  @media (${device.tablet}) {
    margin-left: 0;
  }

  h2 {
    margin-bottom: 25px;
  }
  p:first-of-type {
    margin-top: 0;
  }

  @media (${device.desktop}) {
    &:first-child {
      padding-right: 40px;
    }
    &:nth-child(2) {
      margin-right: -5px;
    }
  }
`

export const ActionWrapper = styled(TextWrapper).attrs({
  row: "3",
  column: "3 / 7",
  tabletRow: 3,
  leftMargin: "mobile",
  rightMargin: "mobile",
})`
  margin-top: 31px;
  overflow: hidden;
  margin-bottom: auto;
`

export const StoryCell = styled(GridCell).attrs({
  row: "1 / 4",
  tabletRow: "1 / 4",
  tabletCol: "5 / 8",
  column: "8 / 14",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})`
  margin-top: 20px;

  @media (${device.tablet}) {
    margin-top: -${60 + 22}px;
  }
  @media (${device.desktop}) {
    margin-top: -${80 + 22}px;
    margin-left: 50px;
  }
`
