import React from "react"
import styled from "styled-components"
import { FixedObject, FluidObject } from "gatsby-image"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"

export type NameStripProps = {
  name: string
  image?: {
    fluid?: FluidObject
    fixed?: FixedObject
    video?: {
      thumbnailUrl: string
    }
  }
}

const ImageWrapper = styled.div`
  width: 39px;
  height: 39px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Name = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NameStrip: React.FC<NameStripProps> = ({
  name,
  image,
  ...props
}) => (
  <Container {...props}>
    {image && (
      <ImageWrapper>
        {image.fluid && <GatsbyImageWithIEPolyfill fluid={image.fluid} />}
        {image.video?.thumbnailUrl && <img src={image.video.thumbnailUrl} />}
      </ImageWrapper>
    )}

    <Name>{name}</Name>
  </Container>
)
