import React from "react"
import { WithContactsHeaderProps } from "./PageHeader"
import styled from "styled-components"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { PageTitle } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import device from "../../../../components/Theme/devices"

const Container = styled(GridLayout)`
  grid-template-columns: repeat(6, 1fr);
`

const Title = styled(GridCell).attrs({
  row: 1,
  tabletRow: 1,
  column: "2/7",
  tabletCol: "2/7",
})`
  h1 {
    margin-top: 0;
    margin-bottom: 50px;
  }

  @media (${device.desktop}) {
    margin-right: 20px;
  }
`

const Address = styled(GridCell).attrs({
  row: 2,
  tabletRow: 2,
  column: "2/4",
  tabletCol: "2/4",
})`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  b,
  strong {
    font-weight: 500;
  }

  a {
    color: currentColor;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

export const Contact = styled(Address).attrs((props: { index: number }) => ({
  row: 3,
  tabletRow: 3 + props.index,
  column: props.index === 0 ? "2/4" : "4 / 6",
  tabletCol: "2/5",
}))<{ index: number }>`
  margin-top: 46px;
`

export const WithContactsHeader: React.FC<WithContactsHeaderProps> = props => (
  <Container {...props}>
    <Title>
      <PageTitle>{props.title}</PageTitle>
    </Title>
    <Address>
      <DatoCmsHtmlText html={props.address.address} />
      {props.address.phone}
      <br />
      <a href={`mailto:${props.address.email}`}>{props.address.email}</a>
    </Address>
    {props.contacts?.map((c, i) => (
      <Contact key={c.id} index={i}>
        <b>{c.headerOverline}</b> <br />
        {c.name} <br />
        <a href={`mailto:${c.email}`}>{c.email}</a>
      </Contact>
    ))}
  </Container>
)
