import styled, { css } from "styled-components"
import { SectionTitle } from "../../../../components/Texts"
import device from "../../../../components/Theme/devices"
import { GridCell, defaultGap } from "../../../../components/GridLayout"

export const Title = styled(SectionTitle)`
  text-align: center;
  padding: 0 20px;
  margin-bottom: 0;
  margin-top: 0;

  @media (${device.tablet}) {
    grid-row: 1;
    grid-column: 2 / 8;
  }

  @media (${device.desktop}) {
    grid-column: 5 / 11;
    padding: 0;
  }
`

export const CardWrapperCell = styled(GridCell).attrs({
  row: 2,
  tabletRow: 2,
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})``

export const CardContainer = styled(CardWrapperCell).attrs({
  column: "1 / 15",
  tabletCol: "1 / 9",
})<{
  spacingBetweenCards?: "default" | "small"
  sectionMargins?: "default" | "small"
}>`
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-left: 0;
  margin-right: 0;
  gap: ${defaultGap};

  padding: 40px 0 25px 0;

  > * {
    min-width: 260px;
    @media (min-width: 414px) {
      min-width: 295px;
    }
    flex: 1;

    svg {
      width: 100%;
      height: auto;
    }
  }

  // To ensure padding right on phones & tablets
  :after,
  :before {
    content: "";
    border-right: 10px solid transparent;
    height: 1px;

    @media (${device.largeDesktop}) {
      border-right: 0;
    }
  }

  @media (${device.desktop}) {
    > * {
      min-width: 0;
    }

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }

  @media (${device.largeDesktop}) {
    grid-column: ${props =>
      props.sectionMargins === "small" ? "2 / 14" : "3 / 13"};
    margin: 0 -40px;
    padding: 57px 20px 25px 20px;

    > * {
      min-width: 0;
    }
  }
`

export const StaggeredCardContainer = styled(CardWrapperCell).attrs({
  column: "3 / 13",
  tabletCol: "2 / 8",
})`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 10px;
    margin-bottom: 10px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  @media (${device.tablet}) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    margin-top: 30px;
    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
      i =>
        css`
          > :nth-child(${i}) {
            grid-row: ${i};
            grid-column: ${i % 2 === 1 ? "1 / 5" : "3 / 7"};
          }
        `
    )}
  }

  @media (${device.desktop}) {
    margin-top: 60px;
    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
      i =>
        css`
          > :nth-child(${i}) {
            grid-row: ${i};
            grid-column: ${i % 2 === 1 ? "1 / 4" : "4 / 7"};
          }
        `
    )}
  }
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  svg {
    margin-right: 10px;
    margin-bottom: -2px;
  }

  @media (${device.tablet}) {
    grid-row: 3;
    grid-column: 2 / 8;
    margin-top: 30px;
  }

  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 3 / 13;
  }
`
