import React from "react"
import {
  PageHeaderProps,
  TextOnlyPageHeaderProps,
  WithAuthorHeaderProps,
  WithContactsHeaderProps,
  WithTypewriterEffectProps,
} from "./PageHeader"
import { LeftImage } from "./LeftImage"
import { RightImage } from "./RightImage"
import { graphql } from "gatsby"
import { TextOnlyHeader } from "./TextOnlyHeader"
import { WithContactsHeader } from "./WithContactsHeader"
import { WithAuthorDetailsHeader } from "./WithAuthorDetails"
import { WithTypewriterEffectHeader } from "./WithTypewriterEffect"

export const PageHeader: React.FC<
  | PageHeaderProps
  | TextOnlyPageHeaderProps
  | WithContactsHeaderProps
  | WithAuthorHeaderProps
  | WithTypewriterEffectProps
> = props => {
  if (props.__typename === "DatoCmsHeaderTextOnly") {
    return <TextOnlyHeader {...props} />
  }

  if (props.__typename === "DatoCmsHeaderWithContact") {
    return <WithContactsHeader {...props} />
  }

  if (props.__typename === "DatoCmsHeaderWithAuthorDetail") {
    return <WithAuthorDetailsHeader {...props} />
  }

  if (props.__typename === "DatoCmsHeaderWithTypewriterEffect") {
    return <WithTypewriterEffectHeader {...props} />
  }

  switch (props.designType) {
    case "image at top left":
      return <LeftImage {...props} />
    case "image at top right":
      return <LeftImage reverse {...props} />
    case "image at right":
      return <RightImage {...props} />
    default:
      return null
  }
}

export const fragment = graphql`
  fragment HeaderData on DatoCmsHeader {
    id
    title
    designType
    description
    image {
      ...DatoCmsAssetData
    }
    action {
      ...ActionData
    }
    titleStyle
    greenish
  }

  fragment TextOnlyHeaderData on DatoCmsHeaderTextOnly {
    id
    title
    text
  }

  fragment WithContactHeaderData on DatoCmsHeaderWithContact {
    id
    title
    address {
      id
      name
      address
      email
      phone
    }
    contacts {
      id
      name
      email
      headerOverline
    }
  }

  fragment WithTypewriterEffectHeaderData on DatoCmsHeaderWithTypewriterEffect {
    __typename
    id
    title
    typewrite
    fixedDescription
    image {
      ...DatoCmsAssetData
    }
    actions: action {
      ...ActionData
    }
  }

  fragment WithAuthorHeader on DatoCmsHeaderWithAuthorDetail {
    id
    title
    titleSecondLine
    subtitle
    description
    pageAuthor {
      ...TeamMember_withAvatar
    }
    nameLabel
    socialMediaLinks
    image {
      ...DatoCmsAssetData
    }
    imageStyle
  }
`
