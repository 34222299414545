import React from "react"
import { PageHeader } from "./sections/PageHeader"
import { DescriptionPlusMessaging } from "./sections/DescriptionPlusMessaging"
import { CardSet } from "./sections/CardSet"
import { MediaPlusText } from "./sections/MediaPlusText"
import { ImageSet } from "./sections/ImageSet"
import { PillSet } from "./sections/PillSet"
import { Marquee } from "./sections/Marquee"
import { Gallery } from "./sections/Gallery"
import { Form } from "./sections/Form"
import { TwoTextColumns } from "./sections/TwoTextColumns"
import { JobTable } from "./sections/JobTable"
import { TreeTextColumns } from "./sections/TreeTextColumns"
import { MediaPlusMessaging } from "./sections/MediaPlusMessaging"
import { DescriptionPlusImage } from "./sections/DescriptionPlusImage"
import { MessagingSet } from "./sections/MessagingSet"
import { Image } from "./sections/Image"
import { Text } from "./sections/Text"
import type { DatoCmsContentProps } from "./DatoCmsContent"
import { BuiltinAnimationSection } from "./sections/BuiltInAnimation"
import { marginsAndPaddingsRules, RuleSet } from "./marginsAndPaddingsRules"
import { DescriptionPlusStory } from "./sections/DescriptionPlusStory"
import { ChatForm } from "./sections/ChatForm"
import { TextWithImage } from "./sections/TextWithImage"

export const DatoCmsContent: React.FC<
  DatoCmsContentProps & {
    halfWidth?: boolean
    before?: DatoCmsContentProps
    after?: DatoCmsContentProps
    verticalMargins: RuleSet
  }
> = props => {
  const marginsFor = marginsAndPaddingsRules(props.verticalMargins)
  const passDown = {
    ...props,
    // @ts-ignore
    vMargins: marginsFor(props, props.before, props.after),
  } as DatoCmsContentProps
  switch (passDown.__typename) {
    case "DatoCmsHeader":
    case "DatoCmsHeaderTextOnly":
    case "DatoCmsHeaderWithContact":
    case "DatoCmsHeaderWithAuthorDetail":
    case "DatoCmsHeaderWithTypewriterEffect":
      return <PageHeader {...passDown} />
    case "DatoCmsDescriptionPlusMessaging":
      return <DescriptionPlusMessaging {...passDown} />
    case "DatoCmsDescriptionPlusStory":
      return <DescriptionPlusStory {...passDown} />
    case "DatoCmsCardSet":
      return <CardSet {...passDown} />
    case "DatoCmsMediaPlusText":
      return <MediaPlusText {...passDown} />
    case "DatoCmsImageSet":
      return <ImageSet {...passDown} />
    case "DatoCmsPillSet":
      return <PillSet {...passDown} />
    case "DatoCmsMarquee":
      return <Marquee {...passDown} />
    case "DatoCmsGallery":
      return <Gallery {...passDown} />
    case "DatoCmsTextTwoColumn":
      return <TwoTextColumns {...passDown} />
    case "DatoCmsChatForm":
      return <ChatForm {...passDown} />
    case "DatoCmsTextWithImage":
      return <TextWithImage {...passDown} />
    case "DatoCmsJobTable":
      return <JobTable {...passDown} />
    case "DatoCmsFormBlock":
      return (
        <Form halfWidth={props.halfWidth} {...passDown} {...passDown.form} />
      )
    case "DatoCmsTextTreeColumn":
      return <TreeTextColumns {...passDown} />
    case "DatoCmsMediaPlusMessaging":
      return <MediaPlusMessaging {...passDown} />
    case "DatoCmsDescriptionPlusImage":
      return <DescriptionPlusImage {...passDown} />
    case "DatoCmsMessagingSet":
      return <MessagingSet {...passDown} />
    case "DatoCmsImage":
      return <Image {...passDown} />
    case "DatoCmsText":
      return <Text {...passDown} />
    case "DatoCmsBuiltInAnimation":
      return <BuiltinAnimationSection {...passDown} />
  }

  return null
}
