import styled, { css } from "styled-components"
import { Body2 } from "../Texts"

export const Container = styled.div<{ onClick?: any }>`
  .gatsby-image-wrapper {
    margin-bottom: 24px;
  }
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  && {
    text-decoration: none;
  }
`

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
`

export const Subtitle = styled(Body2)`
  margin-bottom: 20px;
`

export const Content = Body2
