import styled from "styled-components"
import { OrderedList } from "../../../../components/Lists"
import { GridCell } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"

export const List = styled(OrderedList)`
  padding-left: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
`

export const TextContainer = styled(GridCell)`
  margin-top: 18px;

  > :last-child > :last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 35px;
  }

  @media (${device.tablet}) {
    margin-top: 37px;
  }

  @media (${device.desktop}) {
    margin: auto 45px auto -15px;

    h2 {
      margin-bottom: 38px;
    }
  }
`
