import styled, { css } from "styled-components"
import { GridCell, GridLayout } from "../../components/GridLayout"
import device from "../../components/Theme/devices"
import { PageTitle, SectionTitle } from "../../components/Texts"

export const Container = styled(GridLayout).attrs({ forwardedAs: "header" })`
  padding-top: 30px;
  padding-bottom: 60px;
  @media (${device.tablet}) {
    padding-top: 250px;
  }

  @media (${device.desktop}) {
    padding-bottom: 115px;
  }
`

export const FirstColCell = styled(GridCell).attrs({
  column: "3 / 8",
  tabletCol: "2 / 6",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})`
  p:first-of-type {
    margin-top: 0;
  }
`

export const CategoriesWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 40px;
  @media (${device.tablet}) {
    order: 100000;
    margin-top: 40px;
    margin-bottom: 0;
  }
`

export const CategoriesTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  @media (${device.desktop}) {
    font-size: 26px;

    line-height: 39px;
  }
`

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  > * {
    margin: 5px;
  }
`

export const DescriptionCell = styled(FirstColCell).attrs({
  column: "3 / 7",
  tabletCol: "2 / 5",
})`
  display: flex;
  flex-direction: column;

  p:last-of-type {
    margin-bottom: 0;
  }
`

export const ImageCell = styled(GridCell).attrs({
  column: "8 / 15",
  tabletCol: "5 / 9",
  row: 2,
  tabletRow: 2,
})<{ imgStyle: "plain" | "round" }>`
  margin-top: 30px;
  ${props =>
    props.imgStyle === "round" &&
    css`
      border-radius: 20px 20px 0 20px;
      overflow: hidden;
      box-shadow: 0 0 20px rgba(134, 130, 115, 0.3);

      @media (${device.phone}) {
        margin-left: 20px;
      }
    `}
`

export const AuthorCell = styled(GridCell).attrs({
  column: "11 / 14",
  tabletCol: "6 / 8",
  row: 1,
  tabletRow: 1,
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;

  @media (${device.phone}) {
    margin-bottom: 20px;
  }

  @media (${device.tablet}) {
    margin-top: 25px;
  }
`

export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
`

export const Avatar = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;
  margin-right: 5px;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const SocialMediaCell = styled(AuthorCell).attrs({ column: "9 / 12" })`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`

export const Title = styled(PageTitle)`
  margin-top: 0;
  :last-child {
    margin-bottom: 15px;
    @media (${device.tablet}) {
      margin-bottom: 0;
    }
  }
`

export const Subtitle = styled(SectionTitle)`
  font-weight: 600;
  margin-top: 40px;
`
