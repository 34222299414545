import React, { useState } from "react"
import { ChatFormProps } from "./chatForm"
import { graphql } from "gatsby"
import * as S from "./styled"
import { NetlifyHiddenInput, useNetlifyForm } from "../../../../hooks/useNetlifyForm"
import { Button } from "../../../../components/Buttons"
import { ChatEffect } from "./components/chatEffect"
import { MinimalControl } from "../Form/minimalControl"

export const ChatForm: React.FC<ChatFormProps> = ({
  teamMember,
  chatContent,
  form,
}) => {
  const { register, submissionState, errors, onSubmit } = useNetlifyForm(
    'Chat Form'
  );

  const avatar = teamMember?.src;
  const chatArray = chatContent.split("\n");

  return (
    <S.ChatFormWrapper>
      <S.ContentColumn>
        <S.Form onSubmit={onSubmit}>
          {avatar && (
            <S.Media>
              <img src={teamMember?.src || ''} alt={''} />
            </S.Media>
          )}
          <S.BubbleColumn>

            {/* Intro Text */}
            {chatArray.map((message, index) => (
              <ChatEffect
                active={submissionState === undefined || submissionState === 'error'}
                out={submissionState === 'ongoing'}
                key={'bubbleContent' + index}
                order={index + 1}
              >
                <S.Bubble>{message}</S.Bubble>
              </ChatEffect>
            ))}

            {/* Email Entry Row */}
            <ChatEffect
              active={submissionState === undefined || submissionState === 'error'}
              out={submissionState === 'ongoing'}
              height={5.5}
              order={chatArray.length + 1}
            >
              <S.ChatInputInline>
                {form &&
                  <MinimalControl register={register} {...form.controls[0]} showPlaceholder={true} />}
                <Button>{form.submitButtonLabel}</Button>
              </S.ChatInputInline>
            </ChatEffect>

            {/* Email Succeed */}
            <ChatEffect active={submissionState === 'ongoing'} out={submissionState === 'ok'}>
              <S.Bubble>
                <S.Loading running={submissionState === 'ongoing'}>
                  <span />
                  <span />
                  <span />
                </S.Loading>
              </S.Bubble>
            </ChatEffect>
            <ChatEffect active={submissionState === 'ok'}>
              <S.Bubble>
                {/* Your email: {emailEntry} */}
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 11L8.0199 12L15 4" stroke="#3377FF" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M1 9L4.0199 12L10.5 4" stroke="#3377FF" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              </S.Bubble>
            </ChatEffect>
            <ChatEffect active={submissionState === 'ok'}>
              <S.Bubble dangerouslySetInnerHTML={{__html: form.thankYouMessage}} />
            </ChatEffect>

            {/* Email Error */}
            <ChatEffect
              active={submissionState === 'error'}
              out={submissionState !== 'error'}
              height={5.5}
              order={chatArray.length + 1}
            >
              <S.Bubble>{form.requiredFieldError}</S.Bubble>
            </ChatEffect>

            <NetlifyHiddenInput formName={'Chat Form'} register={register} />
          </S.BubbleColumn>
        </S.Form>
      </S.ContentColumn>
    </S.ChatFormWrapper>
  )
}
export const fragment = graphql`
  fragment ChatFormData on DatoCmsChatForm {
    id
    teamMember {
      ...DatoCmsAssetData
    }
    chatContent: content
    form {
      ...FormData
    }
  }
`
