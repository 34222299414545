import React, { useState } from "react"
import styled from "styled-components"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"
import { Body2, PageTitle, SectionTitle } from "../../../../components/Texts"
import { PageHeaderProps } from "./PageHeader"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import { GridCellProps } from "../../../../components/GridLayout/GridLayout"
import { SlideInTextAnimation } from "../../../../components/SlideInTextAnimation"
import { Parallax } from "react-scroll-parallax"
import { useImageSlideInSpring } from "../../../../hooks/useImageSlideinSpring"
import { animated } from "react-spring"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"

type CellProps = {
  reverse?: boolean
}

const Container = styled(GridLayout)`
  @media (${device.desktop}) {
    grid-template-rows: 1fr auto auto auto 1fr;
  }
`

const HeaderImage = styled(GridCell).attrs((props: CellProps) => ({
  row: "1 / 6",
  column: props.reverse ? "8 / 14" : "1 / 8",
  tabletRow: 1,
  tabletCol: "1 / 9",
  as: animated.div,
}))<CellProps>`
  overflow: hidden;
  img {
    width: 100%;
  }

  @media (${device.desktop}) {
    margin: auto 0;
  }
  .parallax-outer, .parallax-inner {
    border-radius: 10px;
    overflow: hidden;
  }
`

const TitleCell = styled(GridCell).attrs(
  (props: CellProps) =>
    ({
      row: 3,
      tabletRow: 2,
      column: props.reverse ? "3 / 8" : "9 / 13",
      tabletCol: "2 / 7",
      leftMargin: "phone-only",
      rightMargin: "phone-only",
    } as GridCellProps)
)<{ reverse?: boolean }>`
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 30px;

    @media (${device.tablet}) {
      margin-top: 42px;
    }
    margin-bottom: 20px;
  }

  :first-child h1 {
    @media (${device.tablet}) {
      margin-top: 250px;
    }
  }

  h2 {
    margin-top: 42px;
    white-space: pre-wrap;
    @media (${device.desktop}) {
      margin: 0 55px 0 0;
    }
  }
`

const ActionCell = styled(GridCell).attrs(
  (props: CellProps) =>
    ({
      row: 5,
      tabletRow: 4,
      column: props.reverse ? "3 / 7" : "9 / 12",
      tabletCol: "2 / 6",
      leftMargin: "phone-only",
      rightMargin: "phone-only",
    } as GridCellProps)
)<{ reverse?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 26px;
  margin-bottom: auto;
  > :first-child {
    margin-top: 26px;
  }
  > :last-child {
    margin-bottom: auto;
  }
  overflow: hidden;

  button {
    margin-right: auto;
  }
`

const Description = styled(TitleCell).attrs(
  (props: CellProps) =>
    ({
      row: 4,
      tabletRow: 3,
      column: props.reverse ? "3 / 7" : "9 / 13",
      tabletCol: "2 / 6",
    } as GridCellProps)
)<CellProps>`
  p:first-of-type {
    margin-top: 11px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`

export const LeftImage: React.FC<
  PageHeaderProps & { reverse?: boolean }
> = props => {
  const [titleAnimated, setTitleAnimated] = useState<boolean>(false)
  const { ref: actionRef, spring: actionSpring } = useImageSlideInSpring({})
  return (
    <Container greenish={props.greenish} {...props}>
      {props.image && (
        <HeaderImage reverse={props.reverse} data-headerOverlay="false">
          <Parallax className="custom-class" y={[-5, 5]}>
            <DatoCmsAsset {...props.image} />
          </Parallax>
        </HeaderImage>
      )}

      <TitleCell reverse={props.reverse}>
        {props.titleStyle !== "smaller" ? (
          <PageTitle huge={props.titleStyle === "huge"}>
            <SlideInTextAnimation
              text={props.title}
              onRest={() => setTitleAnimated(true)}
            />
          </PageTitle>
        ) : (
          <SectionTitle>
            <SlideInTextAnimation
              text={props.title}
              onRest={() => setTitleAnimated(true)}
            />
          </SectionTitle>
        )}
      </TitleCell>

      <Description reverse={props.reverse}>
        <SlideInTextAnimation
          text={props.description}
          as={Body2}
          pause={!titleAnimated}
        />
      </Description>

      {props.action && (
        <ActionCell reverse={props.reverse} ref={actionRef}>
          <div style={{ overflow: "hidden" }}>
            <animated.div style={actionSpring}>
              <DatoCmsAction {...props.action} />
            </animated.div>
          </div>
        </ActionCell>
      )}
    </Container>
  )
}
