import React from "react"
import styled from "styled-components"
import { FormLabel } from "../Texts"
import device from "../Theme/devices"
import { colors } from "../Theme/colors"
import { UseFormMethods } from "react-hook-form"

export const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  pointer-events: auto;
  :checked,
  :not(:checked) {
    position: absolute;
    left: -9999px;
    height: 100%;
    overflow: hidden;
    display: none;
  }
  :checked + label,
  :not(:checked) + label {
    position: relative;
    padding-left: 38px;
    cursor: pointer;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    height: 100%;

    color: ${colors.darkGray};
  }
  :checked + label {
    color: ${props => props.theme.textColor};
  }
  :checked + label:before,
  :not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #807f7a;
    background: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    transition: all 250ms ease-out;
  }

  :checked + label:before {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-color: ${props => props.theme.primaryColor};
  }

  :checked + label:after,
  :not(:checked) + label:after {
    box-sizing: border-box;
    border-radius: 2px;
    content: "✔";
    color: black;
    font-size: 17px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  :not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  :checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`

const Wrapper = styled.div<{ index: number }>`
  height: 30px;

  grid-column: ${props => (props.index % 2 === 0 ? 1 : 2)};
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none !important;
  > *:not(:last-child) {
    margin-bottom: 25px;
  }
  @media (${device.desktop}) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    pointer-events: none !important;
    > :last-child,
    > :nth-last-child(2) {
      margin-bottom: 0;
    }
  }
`

type CheckboxGroupProps = {
  options: string[]
  name: string
  getRef: () => any
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = props => (
  <Container>
    {props.options.map((o, i) => (
      <Wrapper key={i} index={i}>
        <Checkbox
          ref={props.getRef()}
          name={props.name}
          value={o}
          id={o + props.name}
        />
        {/*@ts-ignore*/}
        <FormLabel as={"label"} for={o + props.name}>
          {o}
        </FormLabel>
      </Wrapper>
    ))}
  </Container>
)
