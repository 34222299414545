import React from "react"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"
import { DatoCmsHtmlText } from "../../components/DatoCmsHtmlText"
import { Body2 } from "../../components/Texts"
import * as S from "./styled"
import { TeamMemberWithAvatar } from "../../types/TeamMember"
import { StoryTopic } from "../../types/storyTopic"
import { DatoCmsStoryTopicAction } from "../../components/DatoCmsAction"
import { DatoCmsAsset as IDatoCmsAsset } from "../../components/DatoCmsAsset/DatoCmsAsset"
import { DatoCmsAsset } from "../../components/DatoCmsAsset"

type PostHeaderProps = {
  title: string
  titleSecondLine?: string
  subtitle?: string
  showSocialMediaLinks?: boolean
  author: TeamMemberWithAvatar
  description?: string
  image?: IDatoCmsAsset
  imageStyle: "plain" | "round"
} & (
  | { showSocialMediaLinks: true }
  | { showSocialMediaLinks: false; authorLabel: string }
) &
  (
    | { categoriesLabel: string; categories: StoryTopic[] }
    | { categories?: undefined; categoriesLabel?: undefined }
  )

// TODO falta categories

export const PostHeader: React.FC<PostHeaderProps> = ({
  title,
  titleSecondLine,
  subtitle,
  author,
  description,
  image,
  imageStyle,
  ...props
}) => (
  <S.Container greenish {...props}>
    <S.FirstColCell tabletRow={1} row={1}>
      <S.Title style={{ whiteSpace: "pre-wrap" }}>
        {title}
        {titleSecondLine && `\n${titleSecondLine}`}
      </S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.FirstColCell>

    {props.showSocialMediaLinks ? (
      <S.SocialMediaCell>
        {author.socialMediaLinks?.map(s => (
          <React.Fragment key={s.id}>
            <a href={s.url}>{s.label}</a>
            <br />
          </React.Fragment>
        ))}
        <a target={"_blank"} href={`mailto:${author.email}`}>
          {author.email}
        </a>
      </S.SocialMediaCell>
    ) : (
      <S.AuthorCell>
        {props.authorLabel}
        <S.AvatarRow>
          <S.Avatar>
            {author.photo?.fixed && (
              <GatsbyImageWithIEPolyfill fixed={author.photo?.fixed} />
            )}
            {author.photo?.video?.thumbnailUrl && (
              <img src={author.photo.video.thumbnailUrl} alt={author.name} />
            )}
          </S.Avatar>
          <span>{author.name}</span>
        </S.AvatarRow>
      </S.AuthorCell>
    )}

    <S.DescriptionCell tabletRow={2} row={2}>
      {props.categories && props.categories.length > 0 && (
        <S.CategoriesWrapper>
          <S.CategoriesTitle>{props.categoriesLabel}</S.CategoriesTitle>
          <S.CategoriesContainer>
            {props.categories.map(c => (
              <DatoCmsStoryTopicAction key={c.id} gray {...c} />
            ))}
          </S.CategoriesContainer>
        </S.CategoriesWrapper>
      )}
      <DatoCmsHtmlText html={description || ""} as={Body2} />
    </S.DescriptionCell>

    <S.ImageCell imgStyle={imageStyle}>
      {image && <DatoCmsAsset {...image} />}
    </S.ImageCell>
  </S.Container>
)
