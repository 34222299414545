import { SpringValue, useSprings } from "react-spring"
import { MessageBubbleType } from "./messages"

export type MessageBubbleSpring = {
  bubbleScale: SpringValue<number>
  senderOpacity: SpringValue<number>
  imageOpacity?: SpringValue<number>
  imageTransform?: SpringValue<string>
  imageShadowRadius?: SpringValue<number>
}

export const useMessageBubbleSprings: (
  messages: Partial<MessageBubbleType>[],
  visible: boolean
) => MessageBubbleSpring[] = (messages, visible) => {
  const [springs] = useSprings(
    messages.length,
    messages.map((message, index) => ({
      pause: !visible,
      delay: index * 1500 || 500,
      from: {
        bubbleScale: 0,
        senderOpacity: 0,
        imageShadowRadius: 0,
      },
      to: [
        ...(message.image ? [{ imageShadowRadius: 20 }] : []),
        { bubbleScale: 1 },
        { senderOpacity: 1 },
      ],
    })),
    [visible]
  )

  return springs
  /*const trail = useTrail(
    howMany,
    {
      delay: 2000,
      config: k => console.log("key", k) || { duration: 1000 },
      pause: !visible,
      from: {
        scale: 0,
        //textHeight: 0,
      },
      to: {
        scale: 1,
        //textHeight: 1,
      },
    },
    [visible]
  )

  return trail*/
}
