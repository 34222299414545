import React from "react"
import styled from "styled-components"
import { FormLabel } from "../Texts"
import { colors } from "../Theme/colors"

const Input = styled.input.attrs({
  type: "radio",
})`
  :checked,
  :not(:checked) {
    position: absolute;
    left: -9999px;
  }
  :checked + div,
  :not(:checked) + div {
    position: relative;
    padding-left: 38px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${colors.darkGray};
  }
  :checked + div {
    color: ${props => props.theme.textColor};
  }
  :checked + div:before,
  :not(:checked) + div:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #807f7a;
    border-radius: 100%;
    background: #fff;
    transition: all 240ms ease-out;
  }

  :checked + div:before {
    box-shadow: 0 0 4px rgba(88, 88, 88, 0.5);
    border-color: ${props => props.theme.primaryColor};
  }
  :checked + div:after,
  :not(:checked) + div:after {
    content: "";
    width: 21px;
    height: 21px;
    background: ${props => props.theme.secondaryColor};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  :not(:checked) + div:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  :checked + div:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`

const OptionWrapper = styled.label`
  height: 30px;
  flex: 50%;
  width: 50%;
`

const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

type RadioGroupProps = {
  options: string[]
  name: string
  getRef: () => any
}

export const RadioGroup: React.FC<RadioGroupProps> = props => (
  <GroupContainer>
    {props.options.map(op => (
      <OptionWrapper>
        <Input
          ref={props.getRef()}
          key={op}
          id={op + props.name}
          value={op}
          name={props.name}
        />
        {/*@ts-ignore*/}
        <FormLabel
          style={{ display: "flex", alignItems: "center", height: "100%" }}
        >
          {op}
        </FormLabel>
      </OptionWrapper>
    ))}
  </GroupContainer>
)
