import { graphql } from "gatsby"
import { TextWithImageProps } from "./textWithImage"
import React from "react"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import styled from "styled-components"
import device from "../../../../components/Theme/devices"
import { Body2 } from "../../../../components/Texts"

export const TextWithImage: React.FC<TextWithImageProps> = ({
  title,
  twImageContent,
  image,
  action,
  imageAlignment,
  extraText,
}) => {
  return (
    <StyledGridLayout>
      <GridCell
        column={imageAlignment === "right" ? "9 / 13" : "3 / 7"}
        row={"1"}
        tabletCol={imageAlignment === "right" ? "5 / 8" : "2 / 5"}
        tabletRow={"1"}
      >
        {image && <DatoCmsAsset {...image} />}
      </GridCell>
      <GridCell
        column={imageAlignment === "right" ? "3 / 8" : "8 / 13"}
        row={"1"}
        tabletCol={imageAlignment === "right" ? "2 / 5" : "5 / 8"}
        tabletRow={"1"}
      >
        <h1>{title}</h1>
        <Body2 dangerouslySetInnerHTML={{ __html: twImageContent }} />
        {action && (
          <CtaContainer>
            <DatoCmsAction {...action} />
          </CtaContainer>
        )}
        <StyledExtraText
          dangerouslySetInnerHTML={{ __html: extraText || "" }}
        />
      </GridCell>
    </StyledGridLayout>
  )
}
export const fragment = graphql`
  fragment TextWithImageData on DatoCmsTextWithImage {
    id
    title
    twImageContent: content
    image {
      ...DatoCmsAssetData
    }
    action {
      ...ActionData
    }
    imageAlignment
    extraText
  }
`

const StyledExtraText = styled.div`
  font-size: 12px;
  color: hsl(227, 2%, 52%);
  margin-top: 28px;
`

const StyledGridLayout = styled(GridLayout)`
  padding-top: 30px;
  padding-bottom: 60px;
  @media (${device.tablet}) {
    align-items: center;
    padding-bottom: 110px;
  }
  @media (${device.phone}) {
    margin-right: 20px;
    margin-left: 20px;
    gap: 45px;
  }
  h1:first-child {
    margin-top: 0;
  }
`

const CtaContainer = styled.div`
  margin-top: 1.5em;
`
