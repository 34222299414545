import React from "react"
import { graphql } from "gatsby"
import { Marquee as MarqueeComponent } from "../../../../components/Marquee"
import { MarqueeProps } from "./Marquee"
import { Huge, SectionTitle } from "../../../../components/Texts"

export const Marquee: React.FC<MarqueeProps> = ({ marqueeStyle, ...props }) => (
  <MarqueeComponent
    textStyleComponent={marqueeStyle === "huge" ? Huge : SectionTitle}
    forwardedAs="div"
    repeat={marqueeStyle === "huge"}
    speed={0.1}
    {...props}
  />
)

export const fragment = graphql`
  fragment MarqueeData on DatoCmsMarquee {
    __typename
    id
    text
    greenish
    marqueeStyle: style
  }
`
