import React from "react"
import { TextOnlyPageHeaderProps } from "./PageHeader"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import styled from "styled-components"
import { PageTitle } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"

const Container = styled(GridLayout)`
  grid-template-columns: repeat(6, 1fr);
`

const Title = styled(GridCell).attrs({
  row: 1,
  tabletRow: 1,
  column: "2/6",
  tabletCol: "2/6",
})`
  h1 {
    margin-top: 0;
    margin-bottom: 30px;
  }
`

const Text = styled(GridCell).attrs({
  row: 2,
  tabletRow: 2,
  column: "2/6",
  tabletCol: "2/6",
})`
  p {
    font-size: 14px;
  }
  h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 39px;
    margin-bottom: 16px;
  }
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

export const TextOnlyHeader: React.FC<
  TextOnlyPageHeaderProps & { halfWidth?: boolean }
> = ({ title, text, ...props }) => (
  <Container {...props}>
    {title && (
      <Title>
        <PageTitle>{title}</PageTitle>
      </Title>
    )}
    <DatoCmsHtmlText html={text} as={Text} />
  </Container>
)
