import styled, { css } from "styled-components"
import device from "../../../../components/Theme/devices"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { Body2 } from "../../../../components/Texts"
import { colors } from "../../../../components/Theme/colors"

export const MessageContainer = styled(GridLayout)`
  grid-template-columns: repeat(6, 1fr);
  > :first-child {
    margin-top: 0;
  }
`

export const Container = styled(GridLayout).attrs({
  forwardedAs: "form",
})<{ submissionState: "ongoing" | "ok" | "error" | undefined; onSubmit: any }>`
  grid-template-columns: repeat(6, 1fr);
  transition: all 250ms ease-out;
  ${props =>
    props.submissionState === "ongoing" &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}

  ${props =>
    props.submissionState === "ok" &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

export const FormRow = styled(GridCell).attrs({
  column: "2 / 6",
  tabletCol: "2 / 6",
})`
  > *:not(button) {
    width: 100%;
  }
`

export const Hints = styled(FormRow)`
  margin-top: 50px;

  @media (${device.desktop}) {
    margin-top: 200px;
  }
`

export const Error = styled(Body2)`
  color: ${colors.red};
  min-height: 41px;
  padding-top: 5px;
  box-sizing: border-box;
`
