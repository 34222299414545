import React from "react"
import { WithTypewriterEffectProps } from "./PageHeader"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import styled, { css } from "styled-components"
import device from "../../../../components/Theme/devices"
import { Body2, PageTitle } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import { Typewriter } from "../../../../components/Typewritter"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"
import { colors } from "../../../../components/Theme/colors"

const Container = styled(GridLayout)`
  @media (${device.desktop}) {
    grid-template-rows: 1fr auto auto auto 1fr;
  }
`

const HeaderImage = styled(GridCell).attrs({
  row: "1 / 6",
  column: "1 / 8",
  tabletRow: 1,
  tabletCol: "1 / 9",
})`
  > img {
    width: 100%;
  }
`

const TitleCell = styled(GridCell).attrs((props: { withImage: boolean }) => ({
  row: 2,
  tabletRow: 2,
  column: props.withImage ? "9 / 14" : "3 / 14",
  tabletCol: "2 / 7",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
  as: PageTitle,
  forwardedAs: "div",
}))<{ withImage: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  > * {
    @media (${device.desktop}) {
      ${props =>
        props.withImage
          ? css`
              font-size: 38px !important;
              font-weight: 400 !important;
              line-height: 56px !important;
            `
          : css`
              font-size: 70px !important;
              font-weight: 500 !important;
              line-height: 82px !important;
            `}
    }
  }

  @media (${device.tablet}) {
    margin-top: 80px;
  }

  @media (${device.desktop}) {
    margin-top: ${props => (props.withImage ? 80 : 225)}px;
  }

  h1 {
    margin: 0;
  }
  > div {
    color: ${colors.blue};
    height: 45px;
    overflow: visible;
    margin-top: 10px;
    @media (${device.desktop}) {
      height: ${props => (props.withImage ? 59 : 104)}px;
    }
  }
`

const DescriptionCell = styled(GridCell).attrs(
  (props: { withImage: boolean }) => ({
    row: 3,
    tabletRow: 3,
    column: props.withImage ? "9 / 14" : "3 / 11",
    tabletCol: "2 / 7",
    leftMargin: "phone-only",
    rightMargin: "phone-only",
    forwardedAs: Body2,
  })
)<{ withImage: boolean }>`
  margin-top: 50px;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  @media (${device.tablet}) {
    margin-top: ${props => (props.withImage ? 50 : 28)}px;
  }
`

const ActionCell = styled(GridCell).attrs((props: { withImage: boolean }) => ({
  row: 4,
  tabletRow: 4,
  column: props.withImage ? "9 / 14" : "3 / 11",
  tabletCol: "2 / 7",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
}))<{ withImage: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;

  @media (${device.tablet}) {
    margin-left: -13px;
    margin-right: -13px;
  }

  > * {
    margin-left: 13px;
    margin-right: 13px;
    margin-top: 34px;
  }
`

export const WithTypewriterEffectHeader: React.FC<WithTypewriterEffectProps> = props => (
  <Container greenish {...props}>
    {props.image && (
      <HeaderImage>
        {props.image && <DatoCmsAsset {...props.image} />}
      </HeaderImage>
    )}
    <TitleCell withImage={!!props.image}>
      <PageTitle>{props.title}</PageTitle>
      <Typewriter sentences={props.typewrite.split("\n")} />
    </TitleCell>
    <DescriptionCell
      withImage={!!props.image}
      as={DatoCmsHtmlText}
      html={props.fixedDescription}
    />
    <ActionCell withImage={!!props.image}>
      {props.actions &&
        props.actions.map((a, i) => (
          <DatoCmsAction key={a.id} {...a} plain={i > 0} />
        ))}
    </ActionCell>
  </Container>
)
