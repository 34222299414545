import React from "react"
import { MessagingSetProps } from "./MessagingSet"
import { graphql } from "gatsby"
import { GridLayout, GridCell } from "../../../../components/GridLayout"
import { SectionTitle } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import * as S from "./styled"
import { MessagesContainer } from "../../../../components/Messages/MessagesContainer"

export const MessagingSet: React.FC<MessagingSetProps> = ({
  title,
  messagingSet,
  ...props
}) => (
  <GridLayout {...props}>
    <GridCell
      row={1}
      tabletRow={1}
      column={"5 / 11"}
      tabletCol={"3 / 6"}
      as={DatoCmsHtmlText}
      forwardedAs={SectionTitle}
      centered
      html={title}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      style={{ marginTop: 0, marginBottom: 0 }}
    />
    {messagingSet.map((m, i) => (
      <S.MessagingCell index={i} key={i}>
        <MessagesContainer {...m} />
      </S.MessagingCell>
    ))}
  </GridLayout>
)

export const fragment = graphql`
  fragment MessagingSetData on DatoCmsMessagingSet {
    id
    title
    messagingSet {
      ...MessagingData
    }
  }
`
