import React, { useCallback, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
// @ts-ignore
import character1Meta from "../../sprites/character1/character1.json"
// @ts-ignore
import character2Meta from "../../sprites/character2/character2.json"
// @ts-ignore
import character3Meta from "../../sprites/character3/character3.json"

const Animation = styled.div<{ url: string }>`
  background-image: url("${props => props.url}");
  background-size: 900%;
  height: 100%;
`

const isTouchScreen = () => "ontouchstart" in document.documentElement

export const SpriteAnimation: React.FC<{
  name: "character1" | "character2" | "character3"
}> = props => {
  const { publicURL, width, height, frames } = useSpritesData(props.name)

  const hovered = useRef<boolean>(false)

  const onMouseEnter = useCallback(() => {
    if (!isTouchScreen()) {
      hovered.current = true
      animate()
    }
  }, [hovered])

  const onMouseLeave = useCallback(() => {
    if (!isTouchScreen()) hovered.current = false
  }, [hovered])

  const [currentFrame, setCurrentFrame] = useState<number>(0)

  const onTouch = useCallback(() => {
    // no animation on mobile
  }, [])

  const animate = useCallback(() => {
    setCurrentFrame(old => {
      if (!hovered.current && old === 0) return old
      if (!isTouchScreen() || hovered.current) setTimeout(animate, 50)
      return old + 1 === frames.length ? 0 : old + 1
    })
  }, [])

  return (
    <Animation
      url={publicURL}
      style={{
        backgroundPosition: `${frames[currentFrame].x / 2}px ${
          frames[currentFrame].y / 2
        }px`,
        backgroundSize: `${width / 2}px ${height / 2}px`,
      }}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouch}
    />
  )
}

const useSpritesData = (name: "character1" | "character2" | "character3") => {
  const {
    data: { edges },
  } = useStaticQuery(graphql`
    {
      data: allFile(filter: { ext: { eq: ".png" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `) as { data: { edges: { node: { name: string; publicURL: string } }[] } }

  const data = edges.map(({ node }) => node).find(node => node.name === name)!

  const meta =
    name === "character1"
      ? character1Meta
      : name === "character2"
      ? character2Meta
      : character3Meta

  return {
    ...data,
    width: meta.meta.width,
    height: meta.meta.height,
    frames: Object.keys(meta.frames)
      .sort()
      .map(f => {
        const {
          frame: { x, y, w: width, h: height },
          filename,
        } = meta.frames[f] as typeof meta.frames["HH_02_500x500_00000.png"]
        return {
          filename,
          width,
          height,
          x,
          y,
        }
      }),
  }
}
