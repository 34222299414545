import styled from "styled-components"
import { GridCell } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"
import { GridCellProps } from "../../../../components/GridLayout/GridLayout"

export const MessagingCell = styled(GridCell).attrs(
  (props: { index: number }) =>
    ({
      row: props.index + 2,
      tabletRow: props.index + 2,
      column: "",
      tabletCol: "",
      leftMargin: "phone-only",
      rightMargin: "phone-only",
    } as GridCellProps)
)<{ index: number }>`
  margin-top: 40px;

  @media (${device.tablet}) {
    margin-top: 0;
  }
  :nth-child(2) {
    margin-top: 44px;
  }

  :nth-child(4n + 2) {
    grid-column: 3 / 6;
  }
  :nth-child(4n + 3) {
    grid-column: 5 / 8;
  }
  :nth-child(4n + 4) {
    grid-column: 2 / 5;
  }
  :nth-child(4n + 5) {
    grid-column: 4 / 7;
  }

  @media (${device.desktop}) {
    :nth-child(4n + 2) {
      grid-column: 4 / 8;
    }
    :nth-child(4n + 3) {
      grid-column: 9 / 13;
    }
    :nth-child(4n + 4) {
      grid-column: 3 / 7;
    }
    :nth-child(4n + 5) {
      grid-column: 8 / 12;
    }
  }
`
