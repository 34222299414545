import React from "react"
import { WithAuthorHeaderProps } from "./PageHeader"
import { PostHeader } from "../../../PostHeader"

export const WithAuthorDetailsHeader: React.FC<WithAuthorHeaderProps> = ({
  pageAuthor: author,
  nameLabel: authorLabel,
  socialMediaLinks: showSocialMediaLinks,
  ...props
}) => (
  // @ts-ignore
  <PostHeader
    author={author}
    authorLabel={authorLabel}
    {...props}
    showSocialMediaLinks={showSocialMediaLinks}
  />
)
