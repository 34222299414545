import React from "react"
import { graphql } from "gatsby"
import { ImageProps } from "./Image"
import { GridLayout } from "../../../../components/GridLayout"
import * as S from "./styled"
import { MessageBubble } from "../../../../components/Messages/MessageBubble"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"

export const Image: React.FC<ImageProps> = ({
  image,
  author,
  hour,
  caption,
  ...props
}) => (
  <GridLayout partlyAt={25} {...props}>
    <S.ImageCell>{image && <DatoCmsAsset {...image} />}</S.ImageCell>
    {caption && author && hour && (
      <S.BubbleCell>
        <MessageBubble author={author} message={caption} hour={hour} />
      </S.BubbleCell>
    )}
  </GridLayout>
)

export const fragment = graphql`
  fragment ImageData on DatoCmsImage {
    id
    image {
      ...DatoCmsAssetData
    }
    caption
    author
    hour
    greenish
    onlyPartlyGreenish
  }
`
