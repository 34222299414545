import styled from "styled-components"
import { GridCell } from "../../../../components/GridLayout"
import device from "../../../../components/Theme/devices"
import { SectionTitle } from "../../../../components/Texts"

export const Title = styled(SectionTitle)`
  margin-bottom: 16px;
  margin-top: 0;
`

export const PillContainer = styled(GridCell).attrs({
  row: "2",
  tabletRow: 2,
  column: "3 / 8",
  tabletCol: "2 / 7",
  rightMargin: "phone-only",
  leftMargin: "phone-only",
})`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: 15px;
  @media (${device.tablet}) {
    margin-left: -5px;
  }

  button {
    margin: 5px;
  }

  .active {
    opacity: 20%;
    pointer-events: none;
  }
`

export const ActionContainer = styled(GridCell).attrs({
  row: "3",
  column: "3/8",
  tabletRow: 3,
  tabletCol: "2 / 6",
  rightMargin: "phone-only",
  leftMargin: "phone-only",
})`
  margin-top: 16px;
`
