import { graphql } from "gatsby"
import styled from "styled-components"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { BuiltinAnimationProps } from "./BuiltinAnimation"
import React from "react"
import { SpriteAnimation } from "../../../../components/SpriteAnimation"

const Container = styled(GridLayout)`
  grid-template-columns: repeat(6, 1fr);
`

const ImageCell = styled(GridCell).attrs({
  row: 1,
  tabletRow: 1,
  column: "1 / 7",
  tabletCol: "1 / 7",
})`
  display: flex;
  justify-content: center;
  > :first-child {
    height: 250px;
    width: 250px;
  }
`

export const BuiltinAnimationSection: React.FC<BuiltinAnimationProps> = props => (
  <Container>
    <ImageCell>
      {/*@ts-ignore*/}
      <SpriteAnimation name={props.name.replace(" ", "")} />
    </ImageCell>
  </Container>
)

export const fragment = graphql`
  fragment BuiltinAnimationData on DatoCmsBuiltInAnimation {
    id
    __typename
    name
    description
  }
`
