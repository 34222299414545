import React from "react"
import { graphql } from "gatsby"
import { ImageSetProps } from "./ImageSet"
import { SectionTitle, Body2, Small } from "../../../../components/Texts"
import * as S from "./styled"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import { GridLayout } from "../../../../components/GridLayout"
import { SpriteAnimation } from "../../../../components/SpriteAnimation"

export const ImageSet: React.FC<ImageSetProps> = props => (
  <GridLayout {...props}>
    <S.TitleCell
      row={"1"}
      tabletRow={1}
      column={"5/11"}
      tabletCol={"2 / 8"}
      leftMargin={"all"}
      rightMargin={"all"}
    >
      <SectionTitle>{props.title}</SectionTitle>
      <DatoCmsHtmlText as={Body2} html={props.description} />
    </S.TitleCell>

    {props.content.images.map((img, i) => (
      <S.FaceCell
        key={img.id}
        column={`${2 + i * 4} / ${6 + i * 4}`}
        index={i + 1}
      >
        <S.FaceContainer>
          {/*@ts-ignore*/}
          <SpriteAnimation name={img.name.replace(" ", "")} />
        </S.FaceContainer>

        <Small>{img.description}</Small>
      </S.FaceCell>
    ))}

    <S.ActionContainer>
      {props.action && <DatoCmsAction {...props.action} />}
    </S.ActionContainer>
  </GridLayout>
)

export const fragment = graphql`
  fragment ImageSetData on DatoCmsImageSet {
    id
    title
    description
    action {
      ...ActionData
    }
    greenish
    content {
      images {
        __typename
        ... on DatoCmsBuiltInAnimation {
          ...BuiltinAnimationData
        }
      }
    }
  }
`
