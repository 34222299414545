import React from "react"
import * as S from "./styled"

type CardProps = {
  imageElement?: JSX.Element
  title: string
}

export const Card: React.FC<CardProps> = ({ title, ...props }) => (
  <S.Container {...props}>
    <S.Background />
    {props.imageElement}
    <S.CardContentInner>
      <S.Title>{title}</S.Title>
      {props.children}
    </S.CardContentInner>
  </S.Container>
)

export const CardContentWrapper = S.CardContentWrapper

export const CardContentPart = S.CardContentPart

export const CardActionWrapper = S.ActionWrapper
