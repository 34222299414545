import { useForm } from "react-hook-form"
import React, { useCallback, useMemo, useState } from "react"

export const NetlifyHiddenInput: React.FC<{
  formName: string
  register: any
}> = ({ formName, register }) => (
  <input type="hidden" name="form-name" value={formName} ref={register} />
)

const encode = (data: { [k: string]: any }) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const reportSubmission = (formName: string) => {
  //@ts-ignore
  window.dataLayer?.push?.({
    event: `submission.${formName}`,
  })
  //window.gtag &&
  //window.gtag("event", "Submit", {
  //  event_category: "Form",
  //  event_label: formName,
  //})
}

export const useNetlifyForm = (formName: string) => {
  const { register, handleSubmit, errors, ...rest } = useForm()

  const [submissionState, setSubmissionState] = useState<
    "ongoing" | "ok" | "error" | undefined
  >()

  const _onSubmit = useCallback(
    data => {
      setSubmissionState("ongoing")
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(data),
      })
        .then(() => {
          setSubmissionState("ok")
          try {
            reportSubmission(formName)
          } catch (e) {
            // Ignore
          }
        })
        .catch(() => setSubmissionState("error"))
    },
    [formName]
  )

  const onSubmit = useMemo(
    () => handleSubmit(_onSubmit),
    [handleSubmit, _onSubmit]
  )

  return {
    register,
    errors,
    onSubmit,
    submissionState,
    ...rest,
  }
}
