import React from "react"
import { FormControl } from "./Form"
import { Forms } from "../../../../components/FormComponents"
import { UseFormMethods } from "react-hook-form"
import styled from "styled-components"

// Used only on the ChatForm on /pakketten/managing-the-future/ page
export const MinimalControl: React.FC<
  FormControl & {
    register: UseFormMethods["register"]
  } & { showPlaceholder?: boolean }
> = ({ register, ...props }) => {
  return (
    <StyledFormsInput
      ref={register({ required: props.required })}
      name={props.name}
      type={props.valueType}
      placeholder={props.showPlaceholder ? props.name : ''}
    />
  )
}

const StyledFormsInput = styled(Forms.Input)`
  padding: 5px 12px;
  height: auto;
  align-self: stretch;
  font-size: 16px;
  border: 1px solid hsla(0, 0%, 67%);
  &::placeholder {
    color: hsla(0, 0%, 68%);
  }
`