import { MarginsAndPaddingsRulesFn } from "./marginsAndPaddingsRules"
import {
  basicMargins,
  DEFAULT_PADDING_DESKTOP,
  DEFAULT_PADDING_PHONE,
  DEFAULT_PADDING_TABLET,
  isGreenish,
  marginsCss,
  SMALLER_PADDING_DESKTOP,
  SMALLER_PADDING_PHONE,
  SMALLER_PADDING_TABLET,
} from "./base"

export const defaultMarginsAndPaddingsRules: MarginsAndPaddingsRulesFn = (
  block,
  before,
  after
) => {
  // Lets start from some default values
  const base = basicMargins(block, before, after)

  // And now brace yourselves for the exceptions
  switch (block.__typename) {
    case "DatoCmsHeader":
      if (
        before?.__typename === "DatoCmsHeaderWithTypewriterEffect" &&
        block.designType.includes("top")
      ) {
        // homepage: typewriter and top right
        base.paddingBottom = { phone: 50, tablet: 40, desktop: 0 }
        base.paddingTop = undefined
        break
      }
      // On headers, no need for the top padding, as it is added inside the block
      if (block.designType === "image at right") {
        // Except on this one
        break
      } else base.paddingTop = undefined
      break
    case "DatoCmsHeaderWithTypewriterEffect":
      base.paddingTop = undefined
      if (after?.__typename.includes("Header")) {
        // Homepage: typewriter + image at top right
        base.paddingBottom = { phone: 60, tablet: 100, desktop: 191 }
      }

      break
    case "DatoCmsMarquee":
      // Bigger margins on all cases. Using the same ones as on Hoe wij werken - smaller padding desktop
      base.paddingTop = { phone: 20, tablet: 104, desktop: 104 }
      base.paddingBottom = base.paddingTop
      break
    case "DatoCmsDescriptionPlusMessaging":
      // Specific cases
      if (
        !block.alternativeLayout &&
        block.greenish &&
        (before?.__typename === "DatoCmsHeader" ||
          before?.__typename === "DatoCmsHeaderWithTypewriterEffect") &&
        after?.__typename === "DatoCmsCardSet" &&
        //@ts-ignore
        !after?.greenish
      ) {
        // Homepage, located between the header and a cardset
        base.paddingTop!.desktop += 20
        base.paddingTop!.tablet += 20
        base.paddingTop!.phone += 58

        base.paddingBottom!.phone += 20
        base.paddingBottom!.tablet += 49
        base.paddingBottom!.desktop += 49
        break
      }

      if (
        block.alternativeLayout &&
        before?.__typename === "DatoCmsMediaPlusText" &&
        !before.greenish
      ) {
        // Homepage, located before media + text
        base.marginTop = {
          phone: 118 - 40,
          tablet: 321 - 80,
          desktop: 321 - 80,
        }
        break
      }
      if (block.alternativeLayout) {
        // if block is using the alternative layout, add a special margin/padding top
        base.paddingTop!.desktop = 92
        base.paddingTop!.tablet = 92
        if (isGreenish(before) && isGreenish(block)) {
          // In this case don't use margin top as it will create an ugly white space, literally
          base.paddingTop!.desktop = 192
          base.paddingTop!.tablet = 192
        } else {
          base.marginTop = { phone: 0, tablet: 100, desktop: 100 }
        }
      }
      break
    case "DatoCmsCardSet":
      // Homepage: located just right below the header (top image)
      if (
        before?.__typename === "DatoCmsHeader" &&
        before.designType.includes("top")
      ) {
        base.paddingTop = { phone: 60, tablet: 100, desktop: 134 }
        // TODO a seguir vem um description + story
      }
      if (
        !block.greenish &&
        after?.__typename === "DatoCmsDescriptionPlusStory" &&
        after.greenish
      ) {
        // Homepage: before desc + story
        base.paddingBottom = { phone: 60, tablet: 100, desktop: 134 }
      }
      // Homepage, located between description + message & a media + text
      if (
        //@ts-ignore
        !block.greenish &&
        before?.__typename === "DatoCmsDescriptionPlusMessaging" &&
        before.greenish
      ) {
        base.paddingTop = { phone: 50, tablet: 61, desktop: 61 }
      }
      if (after?.__typename === "DatoCmsMediaPlusText" && !after.greenish) {
        base.paddingBottom = { phone: 60, tablet: 98, desktop: 98 }
      }
      break
    case "DatoCmsMediaPlusText":
      // Homepage, located between cardset and desction+messaging
      if (before?.__typename === "DatoCmsCardSet") {
        base.paddingTop = { phone: 60, tablet: 98, desktop: 98 }
      }
      break
    case "DatoCmsImageSet":
      if (
        before?.__typename === "DatoCmsDescriptionPlusMessaging" &&
        before?.greenish &&
        after?.__typename === "DatoCmsPillSet" &&
        !after?.greenish
      ) {
        base.paddingTop = undefined
        base.paddingBottom = { phone: 68, tablet: 96, desktop: 96 }
        // Homepage, greenish, before description + bubbles (greenish) and pillset (plain)
      }

      if (
        before?.__typename === "DatoCmsCardSet" &&
        before?.cardLayout === "staggered" &&
        before?.greenish &&
        block.greenish &&
        after?.__typename === "DatoCmsPillSet" &&
        !after?.greenish
      ) {
        // Paketten, greenish, after staggered greenish cardset, before pillset plain
        base.paddingTop = {
          phone: 80 - SMALLER_PADDING_PHONE,
          tablet: 155 - SMALLER_PADDING_TABLET,
          desktop: 155 - SMALLER_PADDING_DESKTOP,
        }
      }
      if (
        !block.greenish &&
        before?.__typename === "DatoCmsDescriptionPlusMessaging" &&
        !before?.greenish &&
        !before?.messaging &&
        after?.__typename === "DatoCmsCardSet" &&
        !after?.greenish
      ) {
        // Managing today/the future: between desc + messaging & cardset, all plain
        base.paddingTop = {
          phone: 60,
          tablet: 208 - DEFAULT_PADDING_TABLET,
          desktop: 208 - DEFAULT_PADDING_DESKTOP,
        }
        base.paddingBottom = {
          phone: 80,
          tablet: 193 - DEFAULT_PADDING_TABLET,
          desktop: 193 - DEFAULT_PADDING_DESKTOP,
        }
        break
      }
      base.paddingBottom!.desktop = 84
      break
    case "DatoCmsGallery":
      if (
        after?.__typename === "DatoCmsPillSet" &&
        isGreenish(block) === isGreenish(after)
      ) {
        base.paddingBottom = undefined
      }
      break
    case "DatoCmsTextTreeColumn":
      if (
        before?.__typename === "DatoCmsHeader" &&
        block.greenish &&
        after?.__typename === "DatoCmsMediaPlusMessaging" &&
        after?.greenish
      ) {
        // Managing the future. between header and media+messaging
        base.paddingTop = {
          phone: 40,
          tablet: 103 - SMALLER_PADDING_TABLET,
          desktop: 103 - SMALLER_PADDING_DESKTOP,
        }
        base.paddingBottom = base.paddingTop
      }
      break
    case "DatoCmsMediaPlusMessaging":
      if (
        block.greenish &&
        before?.__typename === "DatoCmsTextTreeColumn" &&
        before?.greenish
      ) {
        if (
          after?.__typename === "DatoCmsDescriptionPlusMessaging" &&
          after?.greenish &&
          !after.messaging
        ) {
          // Managing today: between 3cols and desc + messaging (all greenish)
          base.paddingBottom = {
            phone: DEFAULT_PADDING_PHONE,
            tablet: 86 - SMALLER_PADDING_TABLET,
            desktop: 86 - SMALLER_PADDING_DESKTOP,
          }
        }
        if (after?.__typename === "DatoCmsTextTwoColumn" && after?.greenish) {
          // Managing the future: between 3cols and 2cols (all greenish)
          base.paddingBottom = {
            phone: DEFAULT_PADDING_PHONE,
            tablet: 235 - SMALLER_PADDING_TABLET,
            desktop: 235 - SMALLER_PADDING_DESKTOP,
          }
        }
      }
      break
    case "DatoCmsMessagingSet":
      if (
        //@ts-ignore
        !block.greenish &&
        before?.__typename === "DatoCmsDescriptionPlusImage" &&
        after?.__typename === "DatoCmsDescriptionPlusMessaging" &&
        !after.messaging
      ) {
        base.paddingBottom = {
          phone: 80,
          tablet: 204 - SMALLER_PADDING_TABLET,
          desktop: 204 - SMALLER_PADDING_DESKTOP,
        }
      }
      break
    case "DatoCmsText":
      if (before?.__typename === "DatoCmsHeaderWithAuthorDetail") {
        //Tim: 76px between header and first block of text
        base.paddingTop = {
          phone: 40,
          tablet: 76 - SMALLER_PADDING_TABLET,
          desktop: 76 - SMALLER_PADDING_DESKTOP,
        }
      }
  }

  return marginsCss(base)
}
