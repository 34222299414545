import React from "react"
import styled from "styled-components"
import { MessageBubble } from "./MessageBubble"
import { NameStrip } from "./NameStrip"
import device from "../Theme/devices"
import type { MessagingType } from "./messages"
import { useInView } from "react-intersection-observer"
import { useMessageBubbleSprings } from "./MessageBubble.springs"

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  .right {
    margin-left: 30px;
  }

  > div:not(:first-child) {
    margin-top: 25px;
  }

  @media (${device.tablet}) {
    .message {
      margin-bottom: 10px;
      &.left {
        margin-right: 57px;
      }
      &:not(.left) {
        margin-left: 57px;
      }
    }
  }
`

const StaggeredContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (${device.phone}) {
    > :not(:first-child) {
      margin-top: 30px;
    }
  }

  @media (${device.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    > :nth-child(2) {
      margin-top: 150px;
    }
  }
`

const VerticalMessagesContainer: React.FC<MessagingType> = ({
  messages = [],
  conversationName: [name] = [],
  conversationType = "vertical",
  dontAnimate,
  ...props
}) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.8 })
  const springs = useMessageBubbleSprings(messages, inView)
  return (
    <VerticalContainer ref={ref} {...props}>
      {name && <NameStrip {...name} />}
      {springs.map((props, i) => (
        <MessageBubble
          springs={dontAnimate ? undefined : props}
          {...messages[i]}
          //@ts-ignore
          className={`message ${messages[i].atLeftSide ? "left" : ""}`}
          key={i}
        />
      ))}
    </VerticalContainer>
  )
}

const StaggeredMessageContainer: React.FC<MessagingType> = ({
  conversationName,
  messages,
  ...props
}) => {
  const { ref, inView } = useInView({ triggerOnce: true })

  const springs = useMessageBubbleSprings(messages, inView)
  return (
    <StaggeredContainer ref={ref} {...props}>
      {springs.map((m, i) => (
        <MessageBubble
          {...messages[i]}
          springs={props.dontAnimate ? undefined : m}
          key={i}
        />
      ))}
    </StaggeredContainer>
  )
}

export const MessagesContainer: React.FC<MessagingType> = props => {
  switch (props.conversationType) {
    case "staggered":
      return <StaggeredMessageContainer {...props} />
    case "vertical":
    default:
      return <VerticalMessagesContainer {...props} />
  }
}
