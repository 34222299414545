import React from "react"
import { graphql } from "gatsby"
import type { MediaPlusTextProps } from "./MediaPlusText"
import * as S from "./styled"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { Body2, SectionTitle } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { DatoCmsAsset } from "../../../../components/DatoCmsAsset"

export const MediaPlusText: React.FC<MediaPlusTextProps> = props => (
  <GridLayout {...props}>
    <GridCell
      column="1 / 9"
      row="1"
      tabletRow={1}
      tabletCol={"1 / 6"}
      rightMargin="all"
      leftMargin="phone-only"
    >
      {props.image && <DatoCmsAsset {...props.image} />}
    </GridCell>
    <S.TextContainer
      tabletRow={1}
      tabletCol={"6 / 9"}
      column={"10 / 13"}
      row={"1"}
      rightMargin="all"
      leftMargin="phone-only"
    >
      <SectionTitle>{props.title}</SectionTitle>
      <DatoCmsHtmlText html={props.text} as={Body2} replace={{ ol: S.List }} />
    </S.TextContainer>
  </GridLayout>
)

export const fragment = graphql`
  fragment MediaPlusTextData on DatoCmsMediaPlusText {
    id
    title
    text
    image {
      ...DatoCmsAssetData
    }
    greenish
  }
`
