import React from "react"
import { graphql } from "gatsby"
import { TwoTextColumnsProps } from "./TwoTextColumns"
import { GridCell, GridLayout } from "../../../../components/GridLayout"
import { Body2, SectionTitle } from "../../../../components/Texts"
import { DatoCmsHtmlText } from "../../../../components/DatoCmsHtmlText"
import { List } from "../MediaPlusText/styled"
import { DatoCmsAction } from "../../../../components/DatoCmsAction"
import styled from "styled-components"
import device from "../../../../components/Theme/devices"

export const TwoTextColumns: React.FC<TwoTextColumnsProps> = ({
  title,
  textLeft,
  textRight,
  action,
  ...props
}) => (
  <StyledTwoTextColumns padTop padBottom {...props}>
    {title && <GridCell
      row={1}
      column={"3 / 7"}
      tabletRow={1}
      tabletCol={"2 / 5"}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      as={SectionTitle}
    >
      {title}
    </GridCell>}
    <DatoCmsHtmlText
      html={textLeft}
      as={GridCell}
      forwardedAs={Body2}
      row={2}
      column={"3 / 8"}
      tabletRow={2}
      tabletCol={"2 / 5"}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
      cta={<DatoCmsAction {...action} />}
    />
    <DatoCmsHtmlText
      html={textRight}
      replace={{ ol: List }}
      as={GridCell}
      forwardedAs={Body2}
      row={2}
      column={"9 / 13"}
      tabletRow={2}
      tabletCol={"5 / 9"}
      leftMargin={"phone-only"}
      rightMargin={"phone-only"}
    />
  </StyledTwoTextColumns>
)

const StyledTwoTextColumns = styled(GridLayout)`
  @media (${device.phone}) {
    gap: 60px;
  }
`

export const fragment = graphql`
  fragment TwoTextColumnsData on DatoCmsTextTwoColumn {
    id
    title
    textLeft
    textRight
    greenish
    action {
      ...ActionData
    }
  }
`
