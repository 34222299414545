import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";

export const ChatEffect = (
  props: React.PropsWithChildren<{
    active: boolean;
    order?: number;
    height?: number;
    out?: boolean;
  }>
) => {
  const [contentHeight, setContentHeight] = useState<number>(0);
  const innerHeight = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  useEffect(() => {
    const update = () =>
      setContentHeight(Math.ceil(innerHeight.current?.clientHeight || 0));
    const resizeObserver = new ResizeObserver(() => update());
    resizeObserver.observe(innerHeight.current as HTMLElement);
    update();
    return () => window.removeEventListener('resize', update);
  }, []);
  return (
    <StyledChatEffect
      active={inView && props.active}
      ref={ref}
      order={props.order}
      height={contentHeight}
      out={props.out}
    >
      <InnerHeight ref={innerHeight}>{props.children}</InnerHeight>
    </StyledChatEffect>
  );
};

const duration = 0.6;

const InnerHeight = styled.div`
  display: flex;
`;

const StyledChatEffect = styled.div<{
  active: boolean;
  order?: number;
  height?: number;
  out?: boolean;
}>`
  width: 100%;
  height: ${(props) => (props.active ? props.height && props.height + 8 : 0)}px;
  transition: height ${duration}s ease, opacity ${duration}s ease;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  opacity: ${(props) => (props.active ? 1 : 0)};
  > * {
    transition: transform ${duration}s ease;
    transform: translateY(${(props) => (props.active ? 0 : -1.5)}em);
  }
  ${(props) =>
    !props.out &&
    css`
      transition-delay: ${props.order
        ? (props.order * duration).toFixed(1) +
          's, ' +
          (props.order * duration).toFixed(1) +
          's'
        : duration + 's, ' + duration + 's'};
      > * {
        transition-delay: ${props.order
          ? (props.order * duration).toFixed(1) + 's'
          : duration + 's'};
      }
    `}
`;