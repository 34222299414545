import React from "react"
//@ts-ignore
import { ReactComponent as Arrow } from "../../vector/ic-arrow-right.svg"
import styled, { css } from "styled-components"

const Container = styled.div<{ accent?: boolean }>`
  ${props =>
    props.accent &&
    css`
      color: ${props.theme.secondaryColor};
    `}
  svg {
    max-height: 1em;
    margin-right: 10px;
    path {
      fill: currentColor;
    }
  }

  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`

type ArrowedLinkProps = {
  label: string
  accent?: boolean
  [other: string]: any
}
export const ArrowedLink: React.FC<ArrowedLinkProps> = props => (
  <Container {...props}>
    <Arrow />
    {props.label}
  </Container>
)
